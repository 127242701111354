import imageTracer from "imagetracerjs";
import * as opentype from "opentype.js";
import * as _ from "lodash";

import {
  CHARACTER_SET,
  GLYPH_IMAGE_SIZE,
  GlyphGroupStates,
  GLYPHS_PER_SPRITE_TOTAL,
  GLYPHS_PER_SPRITE_COLS,
  ALL_CHARS
} from "./glyphs";
import { generateFontName } from "./fontNameGenerator";

const ORIGIN_X = (1 / 8) * GLYPH_IMAGE_SIZE;
const ORIGIN_Y = (3 / 4) * GLYPH_IMAGE_SIZE;
const ADVANCE_PAD = (1 / 16) * GLYPH_IMAGE_SIZE;
const UNITS_PER_EM = 1000;

const NOT_DEF_GLYPH = new opentype.Glyph({
  name: ".notdef",
  unicode: 0,
  advanceWidth: 650,
  path: new opentype.Path()
});

export async function generateAndDownloadFont(glyphStates: GlyphGroupStates) {
  let fontName = generateFontName();
  let font = await generateFont(glyphStates, fontName);
  font.download(`${fontName}.otf`);
}

export async function generateFontToDataURL(
  glyphStates: GlyphGroupStates,
  chars: string[]
) {
  let fontName = generateFontName();
  let font = await generateFont(glyphStates, fontName, chars);
  let dataView = new DataView(font.toArrayBuffer());
  let blob = new Blob([dataView], { type: "font/opentype" });
  return URL.createObjectURL(blob);
}

async function generateFont(
  glyphStates: GlyphGroupStates,
  fontName: string,
  chars = ALL_CHARS
) {
  let charCanvases = new Map<string, ImageData>();
  for (let i = 0; i < CHARACTER_SET.length; i++) {
    let { name, glyphs } = CHARACTER_SET[i];
    for (let j = 0; j < glyphs.length; j++) {
      let { char, spriteUrls } = glyphs[j];
      if (!_.includes(chars, char)) continue;

      charCanvases.set(
        char,
        await loadGlyphCanvas(spriteUrls, glyphStates[name][j].glyphIndex)
      );
    }
  }

  let resultGlyphs = [NOT_DEF_GLYPH],
    ascender = Number.MIN_VALUE,
    descender = Number.MAX_VALUE;
  for (let i = 0; i < CHARACTER_SET.length; i++) {
    let { name, glyphs } = CHARACTER_SET[i];
    for (let j = 0; j < glyphs.length; j++) {
      let { char } = glyphs[j];
      if (!_.includes(chars, char)) continue;

      let layer = traceCanvas(charCanvases.get(char) as ImageData);
      let path = new opentype.Path();
      if (layer) {
        for (let polygon of layer) {
          if (polygon.isholepath) continue;
          addSegmentsToPath(polygon.segments, path, false);
          path.closePath();
          for (let holeIdx of polygon.holechildren) {
            let holePoly = layer[holeIdx];
            addSegmentsToPath(holePoly.segments, path, true);
            path.closePath();
          }
        }
      }

      let bbox = path.getBoundingBox();
      let advanceWidth =
        bbox.x2 + (ADVANCE_PAD / GLYPH_IMAGE_SIZE) * UNITS_PER_EM;
      ascender = Math.max(ascender, bbox.y2);
      descender = Math.min(descender, bbox.y1);
      resultGlyphs.push(
        new opentype.Glyph({
          name: char,
          unicode: char.charCodeAt(0),
          advanceWidth,
          path
        })
      );
    }
  }

  let font = new opentype.Font({
    familyName: _.capitalize(fontName),
    styleName: "Regular",
    unitsPerEm: UNITS_PER_EM,
    ascender: ascender || 1e-6,
    descender: descender || -1e-6,
    glyphs: resultGlyphs
  });
  return font;
}

function traceCanvas(charCanvas: ImageData) {
  let imageData = imageTracer.imagedataToTracedata(charCanvas, {
    ltres: 0,
    qtres: 0,
    pathomit: 0,
    pal: [{ r: 0, g: 0, b: 0, a: 255 }, { r: 0, g: 0, b: 0, a: 0 }],
    colorquantcycles: 1,
    rightangleenhance: true
  });
  let layerIndex = _.findIndex(imageData.palette, (p: any) => p.a > 0);
  return imageData.layers[layerIndex];
}

function addSegmentsToPath(
  segments: any[],
  path: opentype.Path,
  clockwise: boolean
) {
  let first = true;
  let reverse = isClockwise(segments) !== clockwise;
  let segmentsTraverse = reverse ? _.reverse(segments) : segments;
  for (let { x1, x2, y1, y2 } of segmentsTraverse) {
    if (reverse) {
      [x1, x2, y1, y2] = [x2, x1, y2, y1];
    }
    x1 = ((x1 - ORIGIN_X) / GLYPH_IMAGE_SIZE) * UNITS_PER_EM;
    x2 = ((x2 - ORIGIN_X) / GLYPH_IMAGE_SIZE) * UNITS_PER_EM;
    y1 = -((y1 - ORIGIN_Y) / GLYPH_IMAGE_SIZE) * UNITS_PER_EM;
    y2 = -((y2 - ORIGIN_Y) / GLYPH_IMAGE_SIZE) * UNITS_PER_EM;
    if (first) {
      path.moveTo(x1, y1);
    }
    path.lineTo(x2, y2);
    first = false;
  }
}

function isClockwise(segments: any[]) {
  let area = 0;
  for (let { x1, x2, y1, y2 } of segments) {
    area += x1 * y1;
    area -= x2 * y2;
  }
  return area / 2 > 0;
}

export async function getAdvanceWidth(
  spriteUrls: string[],
  glyphIndex: number
) {
  let glyphCanvas = await loadGlyphCanvas(spriteUrls, glyphIndex);
  let layer = traceCanvas(glyphCanvas);
  let maxX = 0;
  if (layer) {
    for (let polygon of layer) {
      if (polygon.isholepath) continue;
      for (let { x1, x2 } of polygon.segments) {
        maxX = Math.max(x1, Math.max(x2, maxX));
      }
    }
  }
  return maxX + ADVANCE_PAD;
}

export let loadGlyphSprite = _.memoize((url: string) => {
  return new Promise(res => {
    let img = document.createElement("img");
    img.onload = () => res(img);
    img.src = url;
  }) as Promise<HTMLImageElement>;
});

function loadGlyphCanvas(
  urls: string[],
  glyphIndex: number
): Promise<ImageData> {
  let bgIndex = Math.floor(glyphIndex / GLYPHS_PER_SPRITE_TOTAL);
  let indexWithinBg = glyphIndex - bgIndex * GLYPHS_PER_SPRITE_TOTAL;
  let row = Math.floor(indexWithinBg / GLYPHS_PER_SPRITE_COLS);
  let col = Math.floor(indexWithinBg - row * GLYPHS_PER_SPRITE_COLS);
  return loadGlyphSprite(urls[bgIndex]).then(img => {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.width = GLYPH_IMAGE_SIZE;
    canvas.height = GLYPH_IMAGE_SIZE;
    context.drawImage(
      img,
      col * GLYPH_IMAGE_SIZE,
      row * GLYPH_IMAGE_SIZE,
      GLYPH_IMAGE_SIZE,
      GLYPH_IMAGE_SIZE,
      0,
      0,
      GLYPH_IMAGE_SIZE,
      GLYPH_IMAGE_SIZE
    );
    return context.getImageData(0, 0, GLYPH_IMAGE_SIZE, GLYPH_IMAGE_SIZE);
  });
}
