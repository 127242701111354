import * as _ from "lodash";

import {
  CHARACTER_SET,
  GlyphGroup,
  getGlyphBackgroundIndex,
  getGlyphBackgroundCoordinates,
  GLYPH_IMAGE_SIZE
} from "./glyphs";
import { loadGlyphSprite } from "./fontGenerator";

// Group name, then character name as specified in glyphs.ts
const FAVICON_CHARACTER = ["Uppercase", "B"];

export let faviconGroup = CHARACTER_SET.find(
  g => g.name === FAVICON_CHARACTER[0]
) as GlyphGroup;
export let faviconGlyphIndex = _.findIndex(
  faviconGroup.glyphs,
  g => g.char === FAVICON_CHARACTER[1]
);

let faviconGlyph = faviconGroup.glyphs[faviconGlyphIndex];
let linkEl = document.querySelector(
  'link[rel="shortcut icon"]'
) as HTMLLinkElement;
export async function generateFavicon(glyphIndex: number) {
  // Load the sprite corresponding to the index
  let spriteUrl = faviconGlyph.spriteUrls[getGlyphBackgroundIndex(glyphIndex)];
  let { col, row } = getGlyphBackgroundCoordinates(glyphIndex);
  let sprite = await loadGlyphSprite(spriteUrl);

  // Draw the image from the sprite on a canvas
  let canvas = document.createElement("canvas");
  canvas.width = GLYPH_IMAGE_SIZE;
  canvas.height = GLYPH_IMAGE_SIZE;
  let context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.drawImage(
    sprite,
    col,
    row,
    GLYPH_IMAGE_SIZE,
    GLYPH_IMAGE_SIZE,
    0,
    0,
    GLYPH_IMAGE_SIZE,
    GLYPH_IMAGE_SIZE
  );

  // Write the canvas into the favicon element
  linkEl.href = canvas.toDataURL("image/x-icon");
}

export const throttleGenerateFavicon = _.throttle(generateFavicon, 1000);
