import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import * as _ from "lodash";
import {
  GlyphGroupStates,
  CHARACTER_SET,
  getGlyphBackgroundIndex,
  getGlyphBackgroundPosition
} from "./glyphs";

import { getAdvanceWidth } from "./fontGenerator";
import { PangramEditor } from "./PangramEditor";

import "./Pangram.scss";

const INTRO_STEPS = [
  "B",
  "Bi",
  "Bit",
  "Bit ",
  "Bit T",
  "Bit Tr",
  "Bit Tri",
  "Bit Trip",
  "Bit Tripp",
  "Bit Trippe",
  "Bit Tripper"
];

interface CharMatch {
  group: string;
  groupIdx: number;
  charIdx: number;
}

interface PangramProps {
  glyphStates: GlyphGroupStates;
  state: number;
  onLetsGoClick: () => void;
}

type GroupedChar = { element: JSX.Element; cumulativeWidth: number };

export const Pangram: React.SFC<PangramProps> = ({
  glyphStates,
  state,
  onLetsGoClick
}) => {
  let editorRef = useRef<HTMLTextAreaElement>(null);
  let [pangram, setPangram] = useState("");
  let [introDone, setIntroDone] = useState(false);
  let [editorOpen, setEditorOpen] = useState(false);
  let [advanceWidths, setAdvanceWidths] = useState<number[]>([]);

  useEffect(() => {
    let steps = INTRO_STEPS.slice();
    function nextStep() {
      if (steps.length) {
        setPangram(steps.shift() as string);
        setTimeout(nextStep, _.random(60, 140));
      } else {
        (editorRef.current as HTMLTextAreaElement).focus();
        setIntroDone(true);
      }
    }
    setTimeout(nextStep, 500);
  }, []);

  useEffect(() => {
    let isStale = false;
    let advanceWidthPromises = getCharacterMapIndexes(pangram).map(
      charMatch => {
        if (charMatch === null) {
          return Promise.resolve(0);
        } else {
          let { group, groupIdx, charIdx } = charMatch;
          let { spriteUrls } = CHARACTER_SET[groupIdx].glyphs[charIdx];
          let glyphIndex = glyphStates[group][charIdx].glyphIndex;
          return getAdvanceWidth(spriteUrls, glyphIndex);
        }
      }
    );
    Promise.all(advanceWidthPromises).then(
      widths => !isStale && setAdvanceWidths(widths)
    );
    return () => {
      isStale = true;
    };
  }, [pangram, state]);

  function renderCharacters(charMatches: (CharMatch | null)[]) {
    let groups: GroupedChar[][] = [[]];
    let maxWidth = (window.innerWidth - 250) / 3; // Compensate for scale-up
    for (let index = 0; index < charMatches.length; index++) {
      let charMatch = charMatches[index];
      if (charMatch === null) {
        groups.push([]);
      } else {
        let { group, groupIdx, charIdx } = charMatch;
        let { spriteUrls } = CHARACTER_SET[groupIdx].glyphs[charIdx];
        let lastGroup = _.last(groups) as GroupedChar[];
        let widthSoFar =
          lastGroup.length > 0
            ? (_.last(lastGroup) as GroupedChar).cumulativeWidth
            : 0;
        let advanceWidth = advanceWidths[index] || 15;
        if (widthSoFar + advanceWidth >= maxWidth) {
          lastGroup = [];
          groups.push(lastGroup);
          widthSoFar = 0;
        }
        lastGroup.push({
          element: (
            <div
              key={index}
              className="pangram--glyph"
              style={{
                backgroundImage: `url(${
                  spriteUrls[
                    getGlyphBackgroundIndex(
                      glyphStates[group][charIdx].glyphIndex
                    )
                  ]
                })`,
                backgroundPosition: getGlyphBackgroundPosition(
                  glyphStates[group][charIdx].glyphIndex
                ),
                width: advanceWidth
              }}
            />
          ),
          cumulativeWidth: widthSoFar + advanceWidth
        });
      }
    }

    return groups.map((group, groupIdx) => (
      <div className="pangram--word" key={groupIdx}>
        {group.map(g => g.element)}
      </div>
    ));
  }

  return (
    <div className={classNames("pangram", { introDone })}>
      <div className="pangram--content">
        <div
          className="pangram--editableContent"
          onClick={() => setEditorOpen(true)}
        >
          {renderCharacters(getCharacterMapIndexes(pangram))}
        </div>
        <PangramEditor
          isOpen={true}
          pangram={pangram}
          onChangePangram={setPangram}
          onClose={() => setEditorOpen(false)}
          ref={editorRef}
        />
        <p className="pangram--description">
          Create a unique bitfont from a vast space of glyphs generated by a
          neural network.
        </p>
      </div>
    </div>
  );
};

function getCharacterMapIndexes(pangram: string) {
  return pangram.split("").map(getCharacterMapIndex);
}

function getCharacterMapIndex(char: string) {
  for (let groupIdx = 0; groupIdx < CHARACTER_SET.length; groupIdx++) {
    let group = CHARACTER_SET[groupIdx];
    for (let charIdx = 0; charIdx < group.glyphs.length; charIdx++) {
      if (group.glyphs[charIdx].char === char) {
        return { group: group.name, groupIdx, charIdx };
      }
    }
  }
  return null;
}
