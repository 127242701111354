import React from "react";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";

import "./PangramEditor.scss";

interface PangramEditorProps {
  isOpen: boolean;
  pangram: string;
  onChangePangram: (newPangram: string) => void;
  onClose: () => void;
}
export const PangramEditor = React.forwardRef<
  HTMLTextAreaElement,
  PangramEditorProps
>(({ isOpen, pangram, onChangePangram }, ref) => {
  return (
    <div className={classNames("pangramEditor", { isOpen })}>
      <TextareaAutosize
        ref={ref}
        value={pangram}
        spellCheck={false}
        onChange={evt =>
          onChangePangram((evt.target as HTMLTextAreaElement).value)
        }
      />
    </div>
  );
});
