import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import EventListener from "react-event-listener";
import * as _ from "lodash";

import {
  GlyphGroupStates,
  getGlyphBackgroundIndex,
  getGlyphBackgroundPosition,
  GlyphGroup
} from "./glyphs";

import "./GlyphGrid.scss";
import { ZoomedInGlyph } from "./GridAreas";

interface Point {
  x: number;
  y: number;
}
type MaybePoint = Point | null;
interface GlyphGridProps {
  isCurrent: boolean;
  glyphGroups: GlyphGroup[];
  glyphStates: GlyphGroupStates;
  zoomedInGlyph: ZoomedInGlyph;
  onToggleFrozen: (group: string, glyphIdx: number) => void;
  onSetZoomedInGlyph: (glyph: ZoomedInGlyph) => void;
}

const ZOOM_IN_SCALE = 0.8;
//const MAX_VELOCITY = 50;

export const GlyphGrid: React.SFC<GlyphGridProps> = ({
  isCurrent,
  glyphGroups,
  glyphStates,
  zoomedInGlyph,
  onToggleFrozen,
  onSetZoomedInGlyph
}) => {
  let gridRef = useRef<HTMLDivElement>(null);
  let gridTranslation = useRef<Point>({ x: 0, y: 0 });
  let mousePos = useRef<Point>({ x: 0, y: 0 });

  let [zoomingIn, setZoomingIn] = useState(false);

  function onMouseMove(evt: MouseEvent) {
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;
    let mouseRelX = evt.clientX / screenWidth;
    let mouseRelY = evt.clientY / screenHeight;
    mousePos.current.x = mouseRelX;
    mousePos.current.y = mouseRelY;
  }

  useEffect(() => {
    if (!isCurrent) return;
    let running = true,
      first = true;
    let gridEl = gridRef.current as HTMLDivElement;

    function pannerFrame() {
      if (!running) return;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      let gridWidth = gridEl.offsetWidth;
      let gridHeight = gridEl.offsetHeight;
      let sizeDiffX = gridWidth - screenWidth;
      let sizeDiffY = gridHeight - screenHeight;
      let translateX = sizeDiffX / 2 - sizeDiffX * mousePos.current.x;
      let translateY = sizeDiffY / 2 - sizeDiffY * mousePos.current.y;
      if (
        first ||
        gridTranslation.current.x !== translateX ||
        gridTranslation.current.y !== translateY
      ) {
        let displacementX = translateX - gridTranslation.current.x;
        let displacementY = translateY - gridTranslation.current.y;
        if (Math.abs(displacementX) > 1 || Math.abs(displacementY) > 1) {
          let scaleFactor = 0.2;
          translateX = gridTranslation.current.x + displacementX * scaleFactor;
          translateY = gridTranslation.current.y + displacementY * scaleFactor;
        }
        gridTranslation.current = { x: translateX, y: translateY };
        gridEl.style.transform = `translate(${gridTranslation.current.x}px, ${
          gridTranslation.current.y
        }px) scale(1)`;
        first = false;
      }
      requestAnimationFrame(pannerFrame);
    }

    if (zoomedInGlyph) {
      let charEl = zoomedInGlyph.charEl;
      let charElBounds = charEl.getBoundingClientRect();

      let yZoomScale =
        (window.innerHeight / charElBounds.height) * ZOOM_IN_SCALE;
      let xZoomScale = (window.innerWidth / charElBounds.width) * ZOOM_IN_SCALE;
      let zoomScale = Math.min(yZoomScale, xZoomScale);

      let wantedElScreenLeft =
        (window.innerWidth - charElBounds.width * zoomScale) / 2;
      let wantedElScreenTop =
        (window.innerHeight - charElBounds.height * zoomScale) / 2;
      let zoomedElOffsetLeft = charEl.offsetLeft * zoomScale;
      let zoomedElOffsetTop = charEl.offsetTop * zoomScale;
      let parentLeftShift = -(zoomedElOffsetLeft - wantedElScreenLeft);
      let parentTopShift = -(zoomedElOffsetTop - wantedElScreenTop);
      gridEl.style.transform = `translate(${parentLeftShift}px, ${parentTopShift}px) scale(${zoomScale})`;
    } else {
      pannerFrame();
    }

    return () => {
      running = false;
    };
  }, [isCurrent, gridRef.current, zoomedInGlyph]);

  function zoomToChar(char: string, evt: React.MouseEvent) {
    if (zoomedInGlyph === null) {
      let charEl = evt.currentTarget as HTMLElement;
      onSetZoomedInGlyph({ char, charEl });
      setZoomingIn(true);
    } else {
      onSetZoomedInGlyph(null);
      setTimeout(() => setZoomingIn(false), 1000);
    }
  }

  function toggleFrozen(
    group: string,
    glyphIdx: number,
    evt: React.MouseEvent
  ) {
    evt.stopPropagation();
    onToggleFrozen(group, glyphIdx);
  }

  return (
    <div
      ref={gridRef}
      className={classNames("glyphGrid", {
        isZoomedIn: !!zoomedInGlyph,
        isZoomingIn: zoomingIn
      })}
      style={{
        transformOrigin: "0 0"
      }}
    >
      <EventListener target={document} onMouseMove={onMouseMove} />
      {glyphGroups.map(({ name, glyphs }) => (
        <div className="glyphGrid--section" key={name}>
          {glyphs.map(({ char, spriteUrls }, index) => (
            <div
              key={index}
              className={classNames("glyphGrid--glyphCell", {
                isFrozen: glyphStates[name][index].frozen,
                isZoomedIn: zoomedInGlyph && zoomedInGlyph.char === char
              })}
              data-char={char}
              onClick={evt => zoomToChar(char, evt)}
            >
              <button
                className="glyphGrid--freezeGlyph"
                onClick={evt => toggleFrozen(name, index, evt)}
              >
                f
              </button>
              <div
                className="glyphGrid--glyph"
                style={{
                  backgroundImage: `url(${
                    spriteUrls[
                      getGlyphBackgroundIndex(
                        glyphStates[name][index].glyphIndex
                      )
                    ]
                  })`,
                  backgroundPosition: getGlyphBackgroundPosition(
                    glyphStates[name][index].glyphIndex
                  )
                }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
