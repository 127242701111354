import * as _ from "lodash";

import APath0 from "./assets/glyphdata/a/0.png";
import APath1 from "./assets/glyphdata/a/1.png";
import APath2 from "./assets/glyphdata/a/2.png";
import APath3 from "./assets/glyphdata/a/3.png";
import APath4 from "./assets/glyphdata/a/4.png";
import APath5 from "./assets/glyphdata/a/5.png";
import APath6 from "./assets/glyphdata/a/6.png";
import APath7 from "./assets/glyphdata/a/7.png";
import APath8 from "./assets/glyphdata/a/8.png";
import APath9 from "./assets/glyphdata/a/9.png";

import BPath0 from "./assets/glyphdata/b/0.png";
import BPath1 from "./assets/glyphdata/b/1.png";
import BPath2 from "./assets/glyphdata/b/2.png";
import BPath3 from "./assets/glyphdata/b/3.png";
import BPath4 from "./assets/glyphdata/b/4.png";
import BPath5 from "./assets/glyphdata/b/5.png";
import BPath6 from "./assets/glyphdata/b/6.png";
import BPath7 from "./assets/glyphdata/b/7.png";
import BPath8 from "./assets/glyphdata/b/8.png";
import BPath9 from "./assets/glyphdata/b/9.png";

import CPath0 from "./assets/glyphdata/c/0.png";
import CPath1 from "./assets/glyphdata/c/1.png";
import CPath2 from "./assets/glyphdata/c/2.png";
import CPath3 from "./assets/glyphdata/c/3.png";
import CPath4 from "./assets/glyphdata/c/4.png";
import CPath5 from "./assets/glyphdata/c/5.png";
import CPath6 from "./assets/glyphdata/c/6.png";
import CPath7 from "./assets/glyphdata/c/7.png";
import CPath8 from "./assets/glyphdata/c/8.png";
import CPath9 from "./assets/glyphdata/c/9.png";

import DPath0 from "./assets/glyphdata/d/0.png";
import DPath1 from "./assets/glyphdata/d/1.png";
import DPath2 from "./assets/glyphdata/d/2.png";
import DPath3 from "./assets/glyphdata/d/3.png";
import DPath4 from "./assets/glyphdata/d/4.png";
import DPath5 from "./assets/glyphdata/d/5.png";
import DPath6 from "./assets/glyphdata/d/6.png";
import DPath7 from "./assets/glyphdata/d/7.png";
import DPath8 from "./assets/glyphdata/d/8.png";
import DPath9 from "./assets/glyphdata/d/9.png";

import EPath0 from "./assets/glyphdata/e/0.png";
import EPath1 from "./assets/glyphdata/e/1.png";
import EPath2 from "./assets/glyphdata/e/2.png";
import EPath3 from "./assets/glyphdata/e/3.png";
import EPath4 from "./assets/glyphdata/e/4.png";
import EPath5 from "./assets/glyphdata/e/5.png";
import EPath6 from "./assets/glyphdata/e/6.png";
import EPath7 from "./assets/glyphdata/e/7.png";
import EPath8 from "./assets/glyphdata/e/8.png";
import EPath9 from "./assets/glyphdata/e/9.png";

import FPath0 from "./assets/glyphdata/f/0.png";
import FPath1 from "./assets/glyphdata/f/1.png";
import FPath2 from "./assets/glyphdata/f/2.png";
import FPath3 from "./assets/glyphdata/f/3.png";
import FPath4 from "./assets/glyphdata/f/4.png";
import FPath5 from "./assets/glyphdata/f/5.png";
import FPath6 from "./assets/glyphdata/f/6.png";
import FPath7 from "./assets/glyphdata/f/7.png";
import FPath8 from "./assets/glyphdata/f/8.png";
import FPath9 from "./assets/glyphdata/f/9.png";

import GPath0 from "./assets/glyphdata/g/0.png";
import GPath1 from "./assets/glyphdata/g/1.png";
import GPath2 from "./assets/glyphdata/g/2.png";
import GPath3 from "./assets/glyphdata/g/3.png";
import GPath4 from "./assets/glyphdata/g/4.png";
import GPath5 from "./assets/glyphdata/g/5.png";
import GPath6 from "./assets/glyphdata/g/6.png";
import GPath7 from "./assets/glyphdata/g/7.png";
import GPath8 from "./assets/glyphdata/g/8.png";
import GPath9 from "./assets/glyphdata/g/9.png";

import HPath0 from "./assets/glyphdata/h/0.png";
import HPath1 from "./assets/glyphdata/h/1.png";
import HPath2 from "./assets/glyphdata/h/2.png";
import HPath3 from "./assets/glyphdata/h/3.png";
import HPath4 from "./assets/glyphdata/h/4.png";
import HPath5 from "./assets/glyphdata/h/5.png";
import HPath6 from "./assets/glyphdata/h/6.png";
import HPath7 from "./assets/glyphdata/h/7.png";
import HPath8 from "./assets/glyphdata/h/8.png";
import HPath9 from "./assets/glyphdata/h/9.png";

import IPath0 from "./assets/glyphdata/i/0.png";
import IPath1 from "./assets/glyphdata/i/1.png";
import IPath2 from "./assets/glyphdata/i/2.png";
import IPath3 from "./assets/glyphdata/i/3.png";
import IPath4 from "./assets/glyphdata/i/4.png";
import IPath5 from "./assets/glyphdata/i/5.png";
import IPath6 from "./assets/glyphdata/i/6.png";
import IPath7 from "./assets/glyphdata/i/7.png";
import IPath8 from "./assets/glyphdata/i/8.png";
import IPath9 from "./assets/glyphdata/i/9.png";

import JPath0 from "./assets/glyphdata/j/0.png";
import JPath1 from "./assets/glyphdata/j/1.png";
import JPath2 from "./assets/glyphdata/j/2.png";
import JPath3 from "./assets/glyphdata/j/3.png";
import JPath4 from "./assets/glyphdata/j/4.png";
import JPath5 from "./assets/glyphdata/j/5.png";
import JPath6 from "./assets/glyphdata/j/6.png";
import JPath7 from "./assets/glyphdata/j/7.png";
import JPath8 from "./assets/glyphdata/j/8.png";
import JPath9 from "./assets/glyphdata/j/9.png";

import KPath0 from "./assets/glyphdata/k/0.png";
import KPath1 from "./assets/glyphdata/k/1.png";
import KPath2 from "./assets/glyphdata/k/2.png";
import KPath3 from "./assets/glyphdata/k/3.png";
import KPath4 from "./assets/glyphdata/k/4.png";
import KPath5 from "./assets/glyphdata/k/5.png";
import KPath6 from "./assets/glyphdata/k/6.png";
import KPath7 from "./assets/glyphdata/k/7.png";
import KPath8 from "./assets/glyphdata/k/8.png";
import KPath9 from "./assets/glyphdata/k/9.png";

import LPath0 from "./assets/glyphdata/l/0.png";
import LPath1 from "./assets/glyphdata/l/1.png";
import LPath2 from "./assets/glyphdata/l/2.png";
import LPath3 from "./assets/glyphdata/l/3.png";
import LPath4 from "./assets/glyphdata/l/4.png";
import LPath5 from "./assets/glyphdata/l/5.png";
import LPath6 from "./assets/glyphdata/l/6.png";
import LPath7 from "./assets/glyphdata/l/7.png";
import LPath8 from "./assets/glyphdata/l/8.png";
import LPath9 from "./assets/glyphdata/l/9.png";

import MPath0 from "./assets/glyphdata/m/0.png";
import MPath1 from "./assets/glyphdata/m/1.png";
import MPath2 from "./assets/glyphdata/m/2.png";
import MPath3 from "./assets/glyphdata/m/3.png";
import MPath4 from "./assets/glyphdata/m/4.png";
import MPath5 from "./assets/glyphdata/m/5.png";
import MPath6 from "./assets/glyphdata/m/6.png";
import MPath7 from "./assets/glyphdata/m/7.png";
import MPath8 from "./assets/glyphdata/m/8.png";
import MPath9 from "./assets/glyphdata/m/9.png";

import NPath0 from "./assets/glyphdata/n/0.png";
import NPath1 from "./assets/glyphdata/n/1.png";
import NPath2 from "./assets/glyphdata/n/2.png";
import NPath3 from "./assets/glyphdata/n/3.png";
import NPath4 from "./assets/glyphdata/n/4.png";
import NPath5 from "./assets/glyphdata/n/5.png";
import NPath6 from "./assets/glyphdata/n/6.png";
import NPath7 from "./assets/glyphdata/n/7.png";
import NPath8 from "./assets/glyphdata/n/8.png";
import NPath9 from "./assets/glyphdata/n/9.png";

import OPath0 from "./assets/glyphdata/o/0.png";
import OPath1 from "./assets/glyphdata/o/1.png";
import OPath2 from "./assets/glyphdata/o/2.png";
import OPath3 from "./assets/glyphdata/o/3.png";
import OPath4 from "./assets/glyphdata/o/4.png";
import OPath5 from "./assets/glyphdata/o/5.png";
import OPath6 from "./assets/glyphdata/o/6.png";
import OPath7 from "./assets/glyphdata/o/7.png";
import OPath8 from "./assets/glyphdata/o/8.png";
import OPath9 from "./assets/glyphdata/o/9.png";

import PPath0 from "./assets/glyphdata/p/0.png";
import PPath1 from "./assets/glyphdata/p/1.png";
import PPath2 from "./assets/glyphdata/p/2.png";
import PPath3 from "./assets/glyphdata/p/3.png";
import PPath4 from "./assets/glyphdata/p/4.png";
import PPath5 from "./assets/glyphdata/p/5.png";
import PPath6 from "./assets/glyphdata/p/6.png";
import PPath7 from "./assets/glyphdata/p/7.png";
import PPath8 from "./assets/glyphdata/p/8.png";
import PPath9 from "./assets/glyphdata/p/9.png";

import QPath0 from "./assets/glyphdata/q/0.png";
import QPath1 from "./assets/glyphdata/q/1.png";
import QPath2 from "./assets/glyphdata/q/2.png";
import QPath3 from "./assets/glyphdata/q/3.png";
import QPath4 from "./assets/glyphdata/q/4.png";
import QPath5 from "./assets/glyphdata/q/5.png";
import QPath6 from "./assets/glyphdata/q/6.png";
import QPath7 from "./assets/glyphdata/q/7.png";
import QPath8 from "./assets/glyphdata/q/8.png";
import QPath9 from "./assets/glyphdata/q/9.png";

import RPath0 from "./assets/glyphdata/r/0.png";
import RPath1 from "./assets/glyphdata/r/1.png";
import RPath2 from "./assets/glyphdata/r/2.png";
import RPath3 from "./assets/glyphdata/r/3.png";
import RPath4 from "./assets/glyphdata/r/4.png";
import RPath5 from "./assets/glyphdata/r/5.png";
import RPath6 from "./assets/glyphdata/r/6.png";
import RPath7 from "./assets/glyphdata/r/7.png";
import RPath8 from "./assets/glyphdata/r/8.png";
import RPath9 from "./assets/glyphdata/r/9.png";

import SPath0 from "./assets/glyphdata/s/0.png";
import SPath1 from "./assets/glyphdata/s/1.png";
import SPath2 from "./assets/glyphdata/s/2.png";
import SPath3 from "./assets/glyphdata/s/3.png";
import SPath4 from "./assets/glyphdata/s/4.png";
import SPath5 from "./assets/glyphdata/s/5.png";
import SPath6 from "./assets/glyphdata/s/6.png";
import SPath7 from "./assets/glyphdata/s/7.png";
import SPath8 from "./assets/glyphdata/s/8.png";
import SPath9 from "./assets/glyphdata/s/9.png";

import TPath0 from "./assets/glyphdata/t/0.png";
import TPath1 from "./assets/glyphdata/t/1.png";
import TPath2 from "./assets/glyphdata/t/2.png";
import TPath3 from "./assets/glyphdata/t/3.png";
import TPath4 from "./assets/glyphdata/t/4.png";
import TPath5 from "./assets/glyphdata/t/5.png";
import TPath6 from "./assets/glyphdata/t/6.png";
import TPath7 from "./assets/glyphdata/t/7.png";
import TPath8 from "./assets/glyphdata/t/8.png";
import TPath9 from "./assets/glyphdata/t/9.png";

import UPath0 from "./assets/glyphdata/u/0.png";
import UPath1 from "./assets/glyphdata/u/1.png";
import UPath2 from "./assets/glyphdata/u/2.png";
import UPath3 from "./assets/glyphdata/u/3.png";
import UPath4 from "./assets/glyphdata/u/4.png";
import UPath5 from "./assets/glyphdata/u/5.png";
import UPath6 from "./assets/glyphdata/u/6.png";
import UPath7 from "./assets/glyphdata/u/7.png";
import UPath8 from "./assets/glyphdata/u/8.png";
import UPath9 from "./assets/glyphdata/u/9.png";

import VPath0 from "./assets/glyphdata/v/0.png";
import VPath1 from "./assets/glyphdata/v/1.png";
import VPath2 from "./assets/glyphdata/v/2.png";
import VPath3 from "./assets/glyphdata/v/3.png";
import VPath4 from "./assets/glyphdata/v/4.png";
import VPath5 from "./assets/glyphdata/v/5.png";
import VPath6 from "./assets/glyphdata/v/6.png";
import VPath7 from "./assets/glyphdata/v/7.png";
import VPath8 from "./assets/glyphdata/v/8.png";
import VPath9 from "./assets/glyphdata/v/9.png";

import WPath0 from "./assets/glyphdata/w/0.png";
import WPath1 from "./assets/glyphdata/w/1.png";
import WPath2 from "./assets/glyphdata/w/2.png";
import WPath3 from "./assets/glyphdata/w/3.png";
import WPath4 from "./assets/glyphdata/w/4.png";
import WPath5 from "./assets/glyphdata/w/5.png";
import WPath6 from "./assets/glyphdata/w/6.png";
import WPath7 from "./assets/glyphdata/w/7.png";
import WPath8 from "./assets/glyphdata/w/8.png";
import WPath9 from "./assets/glyphdata/w/9.png";

import XPath0 from "./assets/glyphdata/x/0.png";
import XPath1 from "./assets/glyphdata/x/1.png";
import XPath2 from "./assets/glyphdata/x/2.png";
import XPath3 from "./assets/glyphdata/x/3.png";
import XPath4 from "./assets/glyphdata/x/4.png";
import XPath5 from "./assets/glyphdata/x/5.png";
import XPath6 from "./assets/glyphdata/x/6.png";
import XPath7 from "./assets/glyphdata/x/7.png";
import XPath8 from "./assets/glyphdata/x/8.png";
import XPath9 from "./assets/glyphdata/x/9.png";

import YPath0 from "./assets/glyphdata/y/0.png";
import YPath1 from "./assets/glyphdata/y/1.png";
import YPath2 from "./assets/glyphdata/y/2.png";
import YPath3 from "./assets/glyphdata/y/3.png";
import YPath4 from "./assets/glyphdata/y/4.png";
import YPath5 from "./assets/glyphdata/y/5.png";
import YPath6 from "./assets/glyphdata/y/6.png";
import YPath7 from "./assets/glyphdata/y/7.png";
import YPath8 from "./assets/glyphdata/y/8.png";
import YPath9 from "./assets/glyphdata/y/9.png";

import ZPath0 from "./assets/glyphdata/z/0.png";
import ZPath1 from "./assets/glyphdata/z/1.png";
import ZPath2 from "./assets/glyphdata/z/2.png";
import ZPath3 from "./assets/glyphdata/z/3.png";
import ZPath4 from "./assets/glyphdata/z/4.png";
import ZPath5 from "./assets/glyphdata/z/5.png";
import ZPath6 from "./assets/glyphdata/z/6.png";
import ZPath7 from "./assets/glyphdata/z/7.png";
import ZPath8 from "./assets/glyphdata/z/8.png";
import ZPath9 from "./assets/glyphdata/z/9.png";

import aPath0 from "./assets/glyphdata/a_l/0.png";
import aPath1 from "./assets/glyphdata/a_l/1.png";
import aPath2 from "./assets/glyphdata/a_l/2.png";
import aPath3 from "./assets/glyphdata/a_l/3.png";
import aPath4 from "./assets/glyphdata/a_l/4.png";
import aPath5 from "./assets/glyphdata/a_l/5.png";
import aPath6 from "./assets/glyphdata/a_l/6.png";
import aPath7 from "./assets/glyphdata/a_l/7.png";
import aPath8 from "./assets/glyphdata/a_l/8.png";
import aPath9 from "./assets/glyphdata/a_l/9.png";

import bPath0 from "./assets/glyphdata/b_l/0.png";
import bPath1 from "./assets/glyphdata/b_l/1.png";
import bPath2 from "./assets/glyphdata/b_l/2.png";
import bPath3 from "./assets/glyphdata/b_l/3.png";
import bPath4 from "./assets/glyphdata/b_l/4.png";
import bPath5 from "./assets/glyphdata/b_l/5.png";
import bPath6 from "./assets/glyphdata/b_l/6.png";
import bPath7 from "./assets/glyphdata/b_l/7.png";
import bPath8 from "./assets/glyphdata/b_l/8.png";
import bPath9 from "./assets/glyphdata/b_l/9.png";

import cPath0 from "./assets/glyphdata/c_l/0.png";
import cPath1 from "./assets/glyphdata/c_l/1.png";
import cPath2 from "./assets/glyphdata/c_l/2.png";
import cPath3 from "./assets/glyphdata/c_l/3.png";
import cPath4 from "./assets/glyphdata/c_l/4.png";
import cPath5 from "./assets/glyphdata/c_l/5.png";
import cPath6 from "./assets/glyphdata/c_l/6.png";
import cPath7 from "./assets/glyphdata/c_l/7.png";
import cPath8 from "./assets/glyphdata/c_l/8.png";
import cPath9 from "./assets/glyphdata/c_l/9.png";

import dPath0 from "./assets/glyphdata/d_l/0.png";
import dPath1 from "./assets/glyphdata/d_l/1.png";
import dPath2 from "./assets/glyphdata/d_l/2.png";
import dPath3 from "./assets/glyphdata/d_l/3.png";
import dPath4 from "./assets/glyphdata/d_l/4.png";
import dPath5 from "./assets/glyphdata/d_l/5.png";
import dPath6 from "./assets/glyphdata/d_l/6.png";
import dPath7 from "./assets/glyphdata/d_l/7.png";
import dPath8 from "./assets/glyphdata/d_l/8.png";
import dPath9 from "./assets/glyphdata/d_l/9.png";

import ePath0 from "./assets/glyphdata/e_l/0.png";
import ePath1 from "./assets/glyphdata/e_l/1.png";
import ePath2 from "./assets/glyphdata/e_l/2.png";
import ePath3 from "./assets/glyphdata/e_l/3.png";
import ePath4 from "./assets/glyphdata/e_l/4.png";
import ePath5 from "./assets/glyphdata/e_l/5.png";
import ePath6 from "./assets/glyphdata/e_l/6.png";
import ePath7 from "./assets/glyphdata/e_l/7.png";
import ePath8 from "./assets/glyphdata/e_l/8.png";
import ePath9 from "./assets/glyphdata/e_l/9.png";

import fPath0 from "./assets/glyphdata/f_l/0.png";
import fPath1 from "./assets/glyphdata/f_l/1.png";
import fPath2 from "./assets/glyphdata/f_l/2.png";
import fPath3 from "./assets/glyphdata/f_l/3.png";
import fPath4 from "./assets/glyphdata/f_l/4.png";
import fPath5 from "./assets/glyphdata/f_l/5.png";
import fPath6 from "./assets/glyphdata/f_l/6.png";
import fPath7 from "./assets/glyphdata/f_l/7.png";
import fPath8 from "./assets/glyphdata/f_l/8.png";
import fPath9 from "./assets/glyphdata/f_l/9.png";

import gPath0 from "./assets/glyphdata/g_l/0.png";
import gPath1 from "./assets/glyphdata/g_l/1.png";
import gPath2 from "./assets/glyphdata/g_l/2.png";
import gPath3 from "./assets/glyphdata/g_l/3.png";
import gPath4 from "./assets/glyphdata/g_l/4.png";
import gPath5 from "./assets/glyphdata/g_l/5.png";
import gPath6 from "./assets/glyphdata/g_l/6.png";
import gPath7 from "./assets/glyphdata/g_l/7.png";
import gPath8 from "./assets/glyphdata/g_l/8.png";
import gPath9 from "./assets/glyphdata/g_l/9.png";

import hPath0 from "./assets/glyphdata/h_l/0.png";
import hPath1 from "./assets/glyphdata/h_l/1.png";
import hPath2 from "./assets/glyphdata/h_l/2.png";
import hPath3 from "./assets/glyphdata/h_l/3.png";
import hPath4 from "./assets/glyphdata/h_l/4.png";
import hPath5 from "./assets/glyphdata/h_l/5.png";
import hPath6 from "./assets/glyphdata/h_l/6.png";
import hPath7 from "./assets/glyphdata/h_l/7.png";
import hPath8 from "./assets/glyphdata/h_l/8.png";
import hPath9 from "./assets/glyphdata/h_l/9.png";

import iPath0 from "./assets/glyphdata/i_l/0.png";
import iPath1 from "./assets/glyphdata/i_l/1.png";
import iPath2 from "./assets/glyphdata/i_l/2.png";
import iPath3 from "./assets/glyphdata/i_l/3.png";
import iPath4 from "./assets/glyphdata/i_l/4.png";
import iPath5 from "./assets/glyphdata/i_l/5.png";
import iPath6 from "./assets/glyphdata/i_l/6.png";
import iPath7 from "./assets/glyphdata/i_l/7.png";
import iPath8 from "./assets/glyphdata/i_l/8.png";
import iPath9 from "./assets/glyphdata/i_l/9.png";

import jPath0 from "./assets/glyphdata/j_l/0.png";
import jPath1 from "./assets/glyphdata/j_l/1.png";
import jPath2 from "./assets/glyphdata/j_l/2.png";
import jPath3 from "./assets/glyphdata/j_l/3.png";
import jPath4 from "./assets/glyphdata/j_l/4.png";
import jPath5 from "./assets/glyphdata/j_l/5.png";
import jPath6 from "./assets/glyphdata/j_l/6.png";
import jPath7 from "./assets/glyphdata/j_l/7.png";
import jPath8 from "./assets/glyphdata/j_l/8.png";
import jPath9 from "./assets/glyphdata/j_l/9.png";

import kPath0 from "./assets/glyphdata/k_l/0.png";
import kPath1 from "./assets/glyphdata/k_l/1.png";
import kPath2 from "./assets/glyphdata/k_l/2.png";
import kPath3 from "./assets/glyphdata/k_l/3.png";
import kPath4 from "./assets/glyphdata/k_l/4.png";
import kPath5 from "./assets/glyphdata/k_l/5.png";
import kPath6 from "./assets/glyphdata/k_l/6.png";
import kPath7 from "./assets/glyphdata/k_l/7.png";
import kPath8 from "./assets/glyphdata/k_l/8.png";
import kPath9 from "./assets/glyphdata/k_l/9.png";

import lPath0 from "./assets/glyphdata/l_l/0.png";
import lPath1 from "./assets/glyphdata/l_l/1.png";
import lPath2 from "./assets/glyphdata/l_l/2.png";
import lPath3 from "./assets/glyphdata/l_l/3.png";
import lPath4 from "./assets/glyphdata/l_l/4.png";
import lPath5 from "./assets/glyphdata/l_l/5.png";
import lPath6 from "./assets/glyphdata/l_l/6.png";
import lPath7 from "./assets/glyphdata/l_l/7.png";
import lPath8 from "./assets/glyphdata/l_l/8.png";
import lPath9 from "./assets/glyphdata/l_l/9.png";

import mPath0 from "./assets/glyphdata/m_l/0.png";
import mPath1 from "./assets/glyphdata/m_l/1.png";
import mPath2 from "./assets/glyphdata/m_l/2.png";
import mPath3 from "./assets/glyphdata/m_l/3.png";
import mPath4 from "./assets/glyphdata/m_l/4.png";
import mPath5 from "./assets/glyphdata/m_l/5.png";
import mPath6 from "./assets/glyphdata/m_l/6.png";
import mPath7 from "./assets/glyphdata/m_l/7.png";
import mPath8 from "./assets/glyphdata/m_l/8.png";
import mPath9 from "./assets/glyphdata/m_l/9.png";

import nPath0 from "./assets/glyphdata/n_l/0.png";
import nPath1 from "./assets/glyphdata/n_l/1.png";
import nPath2 from "./assets/glyphdata/n_l/2.png";
import nPath3 from "./assets/glyphdata/n_l/3.png";
import nPath4 from "./assets/glyphdata/n_l/4.png";
import nPath5 from "./assets/glyphdata/n_l/5.png";
import nPath6 from "./assets/glyphdata/n_l/6.png";
import nPath7 from "./assets/glyphdata/n_l/7.png";
import nPath8 from "./assets/glyphdata/n_l/8.png";
import nPath9 from "./assets/glyphdata/n_l/9.png";

import oPath0 from "./assets/glyphdata/o_l/0.png";
import oPath1 from "./assets/glyphdata/o_l/1.png";
import oPath2 from "./assets/glyphdata/o_l/2.png";
import oPath3 from "./assets/glyphdata/o_l/3.png";
import oPath4 from "./assets/glyphdata/o_l/4.png";
import oPath5 from "./assets/glyphdata/o_l/5.png";
import oPath6 from "./assets/glyphdata/o_l/6.png";
import oPath7 from "./assets/glyphdata/o_l/7.png";
import oPath8 from "./assets/glyphdata/o_l/8.png";
import oPath9 from "./assets/glyphdata/o_l/9.png";

import pPath0 from "./assets/glyphdata/p_l/0.png";
import pPath1 from "./assets/glyphdata/p_l/1.png";
import pPath2 from "./assets/glyphdata/p_l/2.png";
import pPath3 from "./assets/glyphdata/p_l/3.png";
import pPath4 from "./assets/glyphdata/p_l/4.png";
import pPath5 from "./assets/glyphdata/p_l/5.png";
import pPath6 from "./assets/glyphdata/p_l/6.png";
import pPath7 from "./assets/glyphdata/p_l/7.png";
import pPath8 from "./assets/glyphdata/p_l/8.png";
import pPath9 from "./assets/glyphdata/p_l/9.png";

import qPath0 from "./assets/glyphdata/q_l/0.png";
import qPath1 from "./assets/glyphdata/q_l/1.png";
import qPath2 from "./assets/glyphdata/q_l/2.png";
import qPath3 from "./assets/glyphdata/q_l/3.png";
import qPath4 from "./assets/glyphdata/q_l/4.png";
import qPath5 from "./assets/glyphdata/q_l/5.png";
import qPath6 from "./assets/glyphdata/q_l/6.png";
import qPath7 from "./assets/glyphdata/q_l/7.png";
import qPath8 from "./assets/glyphdata/q_l/8.png";
import qPath9 from "./assets/glyphdata/q_l/9.png";

import rPath0 from "./assets/glyphdata/r_l/0.png";
import rPath1 from "./assets/glyphdata/r_l/1.png";
import rPath2 from "./assets/glyphdata/r_l/2.png";
import rPath3 from "./assets/glyphdata/r_l/3.png";
import rPath4 from "./assets/glyphdata/r_l/4.png";
import rPath5 from "./assets/glyphdata/r_l/5.png";
import rPath6 from "./assets/glyphdata/r_l/6.png";
import rPath7 from "./assets/glyphdata/r_l/7.png";
import rPath8 from "./assets/glyphdata/r_l/8.png";
import rPath9 from "./assets/glyphdata/r_l/9.png";

import sPath0 from "./assets/glyphdata/s_l/0.png";
import sPath1 from "./assets/glyphdata/s_l/1.png";
import sPath2 from "./assets/glyphdata/s_l/2.png";
import sPath3 from "./assets/glyphdata/s_l/3.png";
import sPath4 from "./assets/glyphdata/s_l/4.png";
import sPath5 from "./assets/glyphdata/s_l/5.png";
import sPath6 from "./assets/glyphdata/s_l/6.png";
import sPath7 from "./assets/glyphdata/s_l/7.png";
import sPath8 from "./assets/glyphdata/s_l/8.png";
import sPath9 from "./assets/glyphdata/s_l/9.png";

import tPath0 from "./assets/glyphdata/t_l/0.png";
import tPath1 from "./assets/glyphdata/t_l/1.png";
import tPath2 from "./assets/glyphdata/t_l/2.png";
import tPath3 from "./assets/glyphdata/t_l/3.png";
import tPath4 from "./assets/glyphdata/t_l/4.png";
import tPath5 from "./assets/glyphdata/t_l/5.png";
import tPath6 from "./assets/glyphdata/t_l/6.png";
import tPath7 from "./assets/glyphdata/t_l/7.png";
import tPath8 from "./assets/glyphdata/t_l/8.png";
import tPath9 from "./assets/glyphdata/t_l/9.png";

import uPath0 from "./assets/glyphdata/u_l/0.png";
import uPath1 from "./assets/glyphdata/u_l/1.png";
import uPath2 from "./assets/glyphdata/u_l/2.png";
import uPath3 from "./assets/glyphdata/u_l/3.png";
import uPath4 from "./assets/glyphdata/v_l/4.png";
import uPath5 from "./assets/glyphdata/u_l/5.png";
import uPath6 from "./assets/glyphdata/u_l/6.png";
import uPath7 from "./assets/glyphdata/u_l/7.png";
import uPath8 from "./assets/glyphdata/u_l/8.png";
import uPath9 from "./assets/glyphdata/u_l/9.png";

import vPath0 from "./assets/glyphdata/v_l/0.png";
import vPath1 from "./assets/glyphdata/v_l/1.png";
import vPath2 from "./assets/glyphdata/v_l/2.png";
import vPath3 from "./assets/glyphdata/v_l/3.png";
import vPath4 from "./assets/glyphdata/v_l/4.png";
import vPath5 from "./assets/glyphdata/v_l/5.png";
import vPath6 from "./assets/glyphdata/v_l/6.png";
import vPath7 from "./assets/glyphdata/v_l/7.png";
import vPath8 from "./assets/glyphdata/v_l/8.png";
import vPath9 from "./assets/glyphdata/v_l/9.png";

import wPath0 from "./assets/glyphdata/w_l/0.png";
import wPath1 from "./assets/glyphdata/w_l/1.png";
import wPath2 from "./assets/glyphdata/w_l/2.png";
import wPath3 from "./assets/glyphdata/w_l/3.png";
import wPath4 from "./assets/glyphdata/w_l/4.png";
import wPath5 from "./assets/glyphdata/w_l/5.png";
import wPath6 from "./assets/glyphdata/w_l/6.png";
import wPath7 from "./assets/glyphdata/w_l/7.png";
import wPath8 from "./assets/glyphdata/w_l/8.png";
import wPath9 from "./assets/glyphdata/w_l/9.png";

import xPath0 from "./assets/glyphdata/x_l/0.png";
import xPath1 from "./assets/glyphdata/x_l/1.png";
import xPath2 from "./assets/glyphdata/x_l/2.png";
import xPath3 from "./assets/glyphdata/x_l/3.png";
import xPath4 from "./assets/glyphdata/x_l/4.png";
import xPath5 from "./assets/glyphdata/x_l/5.png";
import xPath6 from "./assets/glyphdata/x_l/6.png";
import xPath7 from "./assets/glyphdata/x_l/7.png";
import xPath8 from "./assets/glyphdata/x_l/8.png";
import xPath9 from "./assets/glyphdata/x_l/9.png";

import yPath0 from "./assets/glyphdata/y_l/0.png";
import yPath1 from "./assets/glyphdata/y_l/1.png";
import yPath2 from "./assets/glyphdata/y_l/2.png";
import yPath3 from "./assets/glyphdata/y_l/3.png";
import yPath4 from "./assets/glyphdata/y_l/4.png";
import yPath5 from "./assets/glyphdata/y_l/5.png";
import yPath6 from "./assets/glyphdata/y_l/6.png";
import yPath7 from "./assets/glyphdata/y_l/7.png";
import yPath8 from "./assets/glyphdata/y_l/8.png";
import yPath9 from "./assets/glyphdata/y_l/9.png";

import zPath0 from "./assets/glyphdata/z_l/0.png";
import zPath1 from "./assets/glyphdata/z_l/1.png";
import zPath2 from "./assets/glyphdata/z_l/2.png";
import zPath3 from "./assets/glyphdata/z_l/3.png";
import zPath4 from "./assets/glyphdata/z_l/4.png";
import zPath5 from "./assets/glyphdata/z_l/5.png";
import zPath6 from "./assets/glyphdata/z_l/6.png";
import zPath7 from "./assets/glyphdata/z_l/7.png";
import zPath8 from "./assets/glyphdata/z_l/8.png";
import zPath9 from "./assets/glyphdata/z_l/9.png";

import zeroPath0 from "./assets/glyphdata/0/0.png";
import zeroPath1 from "./assets/glyphdata/0/1.png";
import zeroPath2 from "./assets/glyphdata/0/2.png";
import zeroPath3 from "./assets/glyphdata/0/3.png";
import zeroPath4 from "./assets/glyphdata/0/4.png";
import zeroPath5 from "./assets/glyphdata/0/5.png";
import zeroPath6 from "./assets/glyphdata/0/6.png";
import zeroPath7 from "./assets/glyphdata/0/7.png";
import zeroPath8 from "./assets/glyphdata/0/8.png";
import zeroPath9 from "./assets/glyphdata/0/9.png";

import onePath0 from "./assets/glyphdata/1/0.png";
import onePath1 from "./assets/glyphdata/1/1.png";
import onePath2 from "./assets/glyphdata/1/2.png";
import onePath3 from "./assets/glyphdata/1/3.png";
import onePath4 from "./assets/glyphdata/1/4.png";
import onePath5 from "./assets/glyphdata/1/5.png";
import onePath6 from "./assets/glyphdata/1/6.png";
import onePath7 from "./assets/glyphdata/1/7.png";
import onePath8 from "./assets/glyphdata/1/8.png";
import onePath9 from "./assets/glyphdata/1/9.png";

import twoPath0 from "./assets/glyphdata/2/0.png";
import twoPath1 from "./assets/glyphdata/2/1.png";
import twoPath2 from "./assets/glyphdata/2/2.png";
import twoPath3 from "./assets/glyphdata/2/3.png";
import twoPath4 from "./assets/glyphdata/2/4.png";
import twoPath5 from "./assets/glyphdata/2/5.png";
import twoPath6 from "./assets/glyphdata/2/6.png";
import twoPath7 from "./assets/glyphdata/2/7.png";
import twoPath8 from "./assets/glyphdata/2/8.png";
import twoPath9 from "./assets/glyphdata/2/9.png";

import threePath0 from "./assets/glyphdata/3/0.png";
import threePath1 from "./assets/glyphdata/3/1.png";
import threePath2 from "./assets/glyphdata/3/2.png";
import threePath3 from "./assets/glyphdata/3/3.png";
import threePath4 from "./assets/glyphdata/3/4.png";
import threePath5 from "./assets/glyphdata/3/5.png";
import threePath6 from "./assets/glyphdata/3/6.png";
import threePath7 from "./assets/glyphdata/3/7.png";
import threePath8 from "./assets/glyphdata/3/8.png";
import threePath9 from "./assets/glyphdata/3/9.png";

import fourPath0 from "./assets/glyphdata/4/0.png";
import fourPath1 from "./assets/glyphdata/4/1.png";
import fourPath2 from "./assets/glyphdata/4/2.png";
import fourPath3 from "./assets/glyphdata/4/3.png";
import fourPath4 from "./assets/glyphdata/4/4.png";
import fourPath5 from "./assets/glyphdata/4/5.png";
import fourPath6 from "./assets/glyphdata/4/6.png";
import fourPath7 from "./assets/glyphdata/4/7.png";
import fourPath8 from "./assets/glyphdata/4/8.png";
import fourPath9 from "./assets/glyphdata/4/9.png";

import fivePath0 from "./assets/glyphdata/5/0.png";
import fivePath1 from "./assets/glyphdata/5/1.png";
import fivePath2 from "./assets/glyphdata/5/2.png";
import fivePath3 from "./assets/glyphdata/5/3.png";
import fivePath4 from "./assets/glyphdata/5/4.png";
import fivePath5 from "./assets/glyphdata/5/5.png";
import fivePath6 from "./assets/glyphdata/5/6.png";
import fivePath7 from "./assets/glyphdata/5/7.png";
import fivePath8 from "./assets/glyphdata/5/8.png";
import fivePath9 from "./assets/glyphdata/5/9.png";

import sixPath0 from "./assets/glyphdata/6/0.png";
import sixPath1 from "./assets/glyphdata/6/1.png";
import sixPath2 from "./assets/glyphdata/6/2.png";
import sixPath3 from "./assets/glyphdata/6/3.png";
import sixPath4 from "./assets/glyphdata/6/4.png";
import sixPath5 from "./assets/glyphdata/6/5.png";
import sixPath6 from "./assets/glyphdata/6/6.png";
import sixPath7 from "./assets/glyphdata/6/7.png";
import sixPath8 from "./assets/glyphdata/6/8.png";
import sixPath9 from "./assets/glyphdata/6/9.png";

import sevenPath0 from "./assets/glyphdata/7/0.png";
import sevenPath1 from "./assets/glyphdata/7/1.png";
import sevenPath2 from "./assets/glyphdata/7/2.png";
import sevenPath3 from "./assets/glyphdata/7/3.png";
import sevenPath4 from "./assets/glyphdata/7/4.png";
import sevenPath5 from "./assets/glyphdata/7/5.png";
import sevenPath6 from "./assets/glyphdata/7/6.png";
import sevenPath7 from "./assets/glyphdata/7/7.png";
import sevenPath8 from "./assets/glyphdata/7/8.png";
import sevenPath9 from "./assets/glyphdata/7/9.png";

import eightPath0 from "./assets/glyphdata/8/0.png";
import eightPath1 from "./assets/glyphdata/8/1.png";
import eightPath2 from "./assets/glyphdata/8/2.png";
import eightPath3 from "./assets/glyphdata/8/3.png";
import eightPath4 from "./assets/glyphdata/8/4.png";
import eightPath5 from "./assets/glyphdata/8/5.png";
import eightPath6 from "./assets/glyphdata/8/6.png";
import eightPath7 from "./assets/glyphdata/8/7.png";
import eightPath8 from "./assets/glyphdata/8/8.png";
import eightPath9 from "./assets/glyphdata/8/9.png";

import ninePath0 from "./assets/glyphdata/9/0.png";
import ninePath1 from "./assets/glyphdata/9/1.png";
import ninePath2 from "./assets/glyphdata/9/2.png";
import ninePath3 from "./assets/glyphdata/9/3.png";
import ninePath4 from "./assets/glyphdata/9/4.png";
import ninePath5 from "./assets/glyphdata/9/5.png";
import ninePath6 from "./assets/glyphdata/9/6.png";
import ninePath7 from "./assets/glyphdata/9/7.png";
import ninePath8 from "./assets/glyphdata/9/8.png";
import ninePath9 from "./assets/glyphdata/9/9.png";

import ampPath0 from "./assets/glyphdata/amp/0.png";
import ampPath1 from "./assets/glyphdata/amp/1.png";
import ampPath2 from "./assets/glyphdata/amp/2.png";
import ampPath3 from "./assets/glyphdata/amp/3.png";
import ampPath4 from "./assets/glyphdata/amp/4.png";
import ampPath5 from "./assets/glyphdata/amp/5.png";
import ampPath6 from "./assets/glyphdata/amp/6.png";
import ampPath7 from "./assets/glyphdata/amp/7.png";
import ampPath8 from "./assets/glyphdata/amp/8.png";
import ampPath9 from "./assets/glyphdata/amp/9.png";

import asteriskPath0 from "./assets/glyphdata/asterisk/0.png";
import asteriskPath1 from "./assets/glyphdata/asterisk/1.png";
import asteriskPath2 from "./assets/glyphdata/asterisk/2.png";
import asteriskPath3 from "./assets/glyphdata/asterisk/3.png";
import asteriskPath4 from "./assets/glyphdata/asterisk/4.png";
import asteriskPath5 from "./assets/glyphdata/asterisk/5.png";
import asteriskPath6 from "./assets/glyphdata/asterisk/6.png";
import asteriskPath7 from "./assets/glyphdata/asterisk/7.png";
import asteriskPath8 from "./assets/glyphdata/asterisk/8.png";
import asteriskPath9 from "./assets/glyphdata/asterisk/9.png";

import atPath0 from "./assets/glyphdata/at/0.png";
import atPath1 from "./assets/glyphdata/at/1.png";
import atPath2 from "./assets/glyphdata/at/2.png";
import atPath3 from "./assets/glyphdata/at/3.png";
import atPath4 from "./assets/glyphdata/at/4.png";
import atPath5 from "./assets/glyphdata/at/5.png";
import atPath6 from "./assets/glyphdata/at/6.png";
import atPath7 from "./assets/glyphdata/at/7.png";
import atPath8 from "./assets/glyphdata/at/8.png";
import atPath9 from "./assets/glyphdata/at/9.png";

import backslashPath0 from "./assets/glyphdata/backslash/0.png";
import backslashPath1 from "./assets/glyphdata/backslash/1.png";
import backslashPath2 from "./assets/glyphdata/backslash/2.png";
import backslashPath3 from "./assets/glyphdata/backslash/3.png";
import backslashPath4 from "./assets/glyphdata/backslash/4.png";
import backslashPath5 from "./assets/glyphdata/backslash/5.png";
import backslashPath6 from "./assets/glyphdata/backslash/6.png";
import backslashPath7 from "./assets/glyphdata/backslash/7.png";
import backslashPath8 from "./assets/glyphdata/backslash/8.png";
import backslashPath9 from "./assets/glyphdata/backslash/9.png";

import backtickPath0 from "./assets/glyphdata/backtick/0.png";
import backtickPath1 from "./assets/glyphdata/backtick/1.png";
import backtickPath2 from "./assets/glyphdata/backtick/2.png";
import backtickPath3 from "./assets/glyphdata/backtick/3.png";
import backtickPath4 from "./assets/glyphdata/backtick/4.png";
import backtickPath5 from "./assets/glyphdata/backtick/5.png";
import backtickPath6 from "./assets/glyphdata/backtick/6.png";
import backtickPath7 from "./assets/glyphdata/backtick/7.png";
import backtickPath8 from "./assets/glyphdata/backtick/8.png";
import backtickPath9 from "./assets/glyphdata/backtick/9.png";

import caretPath0 from "./assets/glyphdata/caret/0.png";
import caretPath1 from "./assets/glyphdata/caret/1.png";
import caretPath2 from "./assets/glyphdata/caret/2.png";
import caretPath3 from "./assets/glyphdata/caret/3.png";
import caretPath4 from "./assets/glyphdata/caret/4.png";
import caretPath5 from "./assets/glyphdata/caret/5.png";
import caretPath6 from "./assets/glyphdata/caret/6.png";
import caretPath7 from "./assets/glyphdata/caret/7.png";
import caretPath8 from "./assets/glyphdata/caret/8.png";
import caretPath9 from "./assets/glyphdata/caret/9.png";

import centPath0 from "./assets/glyphdata/cent/0.png";
import centPath1 from "./assets/glyphdata/cent/1.png";
import centPath2 from "./assets/glyphdata/cent/2.png";
import centPath3 from "./assets/glyphdata/cent/3.png";
import centPath4 from "./assets/glyphdata/cent/4.png";
import centPath5 from "./assets/glyphdata/cent/5.png";
import centPath6 from "./assets/glyphdata/cent/6.png";
import centPath7 from "./assets/glyphdata/cent/7.png";
import centPath8 from "./assets/glyphdata/cent/8.png";
import centPath9 from "./assets/glyphdata/cent/9.png";

import colonPath0 from "./assets/glyphdata/colon/0.png";
import colonPath1 from "./assets/glyphdata/colon/1.png";
import colonPath2 from "./assets/glyphdata/colon/2.png";
import colonPath3 from "./assets/glyphdata/colon/3.png";
import colonPath4 from "./assets/glyphdata/colon/4.png";
import colonPath5 from "./assets/glyphdata/colon/5.png";
import colonPath6 from "./assets/glyphdata/colon/6.png";
import colonPath7 from "./assets/glyphdata/colon/7.png";
import colonPath8 from "./assets/glyphdata/colon/8.png";
import colonPath9 from "./assets/glyphdata/colon/9.png";

import commaPath0 from "./assets/glyphdata/comma/0.png";
import commaPath1 from "./assets/glyphdata/comma/1.png";
import commaPath2 from "./assets/glyphdata/comma/2.png";
import commaPath3 from "./assets/glyphdata/comma/3.png";
import commaPath4 from "./assets/glyphdata/comma/4.png";
import commaPath5 from "./assets/glyphdata/comma/5.png";
import commaPath6 from "./assets/glyphdata/comma/6.png";
import commaPath7 from "./assets/glyphdata/comma/7.png";
import commaPath8 from "./assets/glyphdata/comma/8.png";
import commaPath9 from "./assets/glyphdata/comma/9.png";

import copyPath0 from "./assets/glyphdata/copy/0.png";
import copyPath1 from "./assets/glyphdata/copy/1.png";
import copyPath2 from "./assets/glyphdata/copy/2.png";
import copyPath3 from "./assets/glyphdata/copy/3.png";
import copyPath4 from "./assets/glyphdata/copy/4.png";
import copyPath5 from "./assets/glyphdata/copy/5.png";
import copyPath6 from "./assets/glyphdata/copy/6.png";
import copyPath7 from "./assets/glyphdata/copy/7.png";
import copyPath8 from "./assets/glyphdata/copy/8.png";
import copyPath9 from "./assets/glyphdata/copy/9.png";

import curlyClosePath0 from "./assets/glyphdata/curly_close/0.png";
import curlyClosePath1 from "./assets/glyphdata/curly_close/1.png";
import curlyClosePath2 from "./assets/glyphdata/curly_close/2.png";
import curlyClosePath3 from "./assets/glyphdata/curly_close/3.png";
import curlyClosePath4 from "./assets/glyphdata/curly_close/4.png";
import curlyClosePath5 from "./assets/glyphdata/curly_close/5.png";
import curlyClosePath6 from "./assets/glyphdata/curly_close/6.png";
import curlyClosePath7 from "./assets/glyphdata/curly_close/7.png";
import curlyClosePath8 from "./assets/glyphdata/curly_close/8.png";
import curlyClosePath9 from "./assets/glyphdata/curly_close/9.png";

import curlyOpenPath0 from "./assets/glyphdata/curly_open/0.png";
import curlyOpenPath1 from "./assets/glyphdata/curly_open/1.png";
import curlyOpenPath2 from "./assets/glyphdata/curly_open/2.png";
import curlyOpenPath3 from "./assets/glyphdata/curly_open/3.png";
import curlyOpenPath4 from "./assets/glyphdata/curly_open/4.png";
import curlyOpenPath5 from "./assets/glyphdata/curly_open/5.png";
import curlyOpenPath6 from "./assets/glyphdata/curly_open/6.png";
import curlyOpenPath7 from "./assets/glyphdata/curly_open/7.png";
import curlyOpenPath8 from "./assets/glyphdata/curly_open/8.png";
import curlyOpenPath9 from "./assets/glyphdata/curly_open/9.png";

import dollarPath0 from "./assets/glyphdata/dollar/0.png";
import dollarPath1 from "./assets/glyphdata/dollar/1.png";
import dollarPath2 from "./assets/glyphdata/dollar/2.png";
import dollarPath3 from "./assets/glyphdata/dollar/3.png";
import dollarPath4 from "./assets/glyphdata/dollar/4.png";
import dollarPath5 from "./assets/glyphdata/dollar/5.png";
import dollarPath6 from "./assets/glyphdata/dollar/6.png";
import dollarPath7 from "./assets/glyphdata/dollar/7.png";
import dollarPath8 from "./assets/glyphdata/dollar/8.png";
import dollarPath9 from "./assets/glyphdata/dollar/9.png";

import dquoPath0 from "./assets/glyphdata/dquo/0.png";
import dquoPath1 from "./assets/glyphdata/dquo/1.png";
import dquoPath2 from "./assets/glyphdata/dquo/2.png";
import dquoPath3 from "./assets/glyphdata/dquo/3.png";
import dquoPath4 from "./assets/glyphdata/dquo/4.png";
import dquoPath5 from "./assets/glyphdata/dquo/5.png";
import dquoPath6 from "./assets/glyphdata/dquo/6.png";
import dquoPath7 from "./assets/glyphdata/dquo/7.png";
import dquoPath8 from "./assets/glyphdata/dquo/8.png";
import dquoPath9 from "./assets/glyphdata/dquo/9.png";

import eqPath0 from "./assets/glyphdata/eq/0.png";
import eqPath1 from "./assets/glyphdata/eq/1.png";
import eqPath2 from "./assets/glyphdata/eq/2.png";
import eqPath3 from "./assets/glyphdata/eq/3.png";
import eqPath4 from "./assets/glyphdata/eq/4.png";
import eqPath5 from "./assets/glyphdata/eq/5.png";
import eqPath6 from "./assets/glyphdata/eq/6.png";
import eqPath7 from "./assets/glyphdata/eq/7.png";
import eqPath8 from "./assets/glyphdata/eq/8.png";
import eqPath9 from "./assets/glyphdata/eq/9.png";

import euroPath0 from "./assets/glyphdata/euro/0.png";
import euroPath1 from "./assets/glyphdata/euro/1.png";
import euroPath2 from "./assets/glyphdata/euro/2.png";
import euroPath3 from "./assets/glyphdata/euro/3.png";
import euroPath4 from "./assets/glyphdata/euro/4.png";
import euroPath5 from "./assets/glyphdata/euro/5.png";
import euroPath6 from "./assets/glyphdata/euro/6.png";
import euroPath7 from "./assets/glyphdata/euro/7.png";
import euroPath8 from "./assets/glyphdata/euro/8.png";
import euroPath9 from "./assets/glyphdata/euro/9.png";

import exclamationPath0 from "./assets/glyphdata/exclamation/0.png";
import exclamationPath1 from "./assets/glyphdata/exclamation/1.png";
import exclamationPath2 from "./assets/glyphdata/exclamation/2.png";
import exclamationPath3 from "./assets/glyphdata/exclamation/3.png";
import exclamationPath4 from "./assets/glyphdata/exclamation/4.png";
import exclamationPath5 from "./assets/glyphdata/exclamation/5.png";
import exclamationPath6 from "./assets/glyphdata/exclamation/6.png";
import exclamationPath7 from "./assets/glyphdata/exclamation/7.png";
import exclamationPath8 from "./assets/glyphdata/exclamation/8.png";
import exclamationPath9 from "./assets/glyphdata/exclamation/9.png";

import fwSlashPath0 from "./assets/glyphdata/fwslash/0.png";
import fwSlashPath1 from "./assets/glyphdata/fwslash/1.png";
import fwSlashPath2 from "./assets/glyphdata/fwslash/2.png";
import fwSlashPath3 from "./assets/glyphdata/fwslash/3.png";
import fwSlashPath4 from "./assets/glyphdata/fwslash/4.png";
import fwSlashPath5 from "./assets/glyphdata/fwslash/5.png";
import fwSlashPath6 from "./assets/glyphdata/fwslash/6.png";
import fwSlashPath7 from "./assets/glyphdata/fwslash/7.png";
import fwSlashPath8 from "./assets/glyphdata/fwslash/8.png";
import fwSlashPath9 from "./assets/glyphdata/fwslash/9.png";

import gtPath0 from "./assets/glyphdata/gt/0.png";
import gtPath1 from "./assets/glyphdata/gt/1.png";
import gtPath2 from "./assets/glyphdata/gt/2.png";
import gtPath3 from "./assets/glyphdata/gt/3.png";
import gtPath4 from "./assets/glyphdata/gt/4.png";
import gtPath5 from "./assets/glyphdata/gt/5.png";
import gtPath6 from "./assets/glyphdata/gt/6.png";
import gtPath7 from "./assets/glyphdata/gt/7.png";
import gtPath8 from "./assets/glyphdata/gt/8.png";
import gtPath9 from "./assets/glyphdata/gt/9.png";

import hashPath0 from "./assets/glyphdata/hash/0.png";
import hashPath1 from "./assets/glyphdata/hash/1.png";
import hashPath2 from "./assets/glyphdata/hash/2.png";
import hashPath3 from "./assets/glyphdata/hash/3.png";
import hashPath4 from "./assets/glyphdata/hash/4.png";
import hashPath5 from "./assets/glyphdata/hash/5.png";
import hashPath6 from "./assets/glyphdata/hash/6.png";
import hashPath7 from "./assets/glyphdata/hash/7.png";
import hashPath8 from "./assets/glyphdata/hash/8.png";
import hashPath9 from "./assets/glyphdata/hash/9.png";

import ltPath0 from "./assets/glyphdata/lt/0.png";
import ltPath1 from "./assets/glyphdata/lt/1.png";
import ltPath2 from "./assets/glyphdata/lt/2.png";
import ltPath3 from "./assets/glyphdata/lt/3.png";
import ltPath4 from "./assets/glyphdata/lt/4.png";
import ltPath5 from "./assets/glyphdata/lt/5.png";
import ltPath6 from "./assets/glyphdata/lt/6.png";
import ltPath7 from "./assets/glyphdata/lt/7.png";
import ltPath8 from "./assets/glyphdata/lt/8.png";
import ltPath9 from "./assets/glyphdata/lt/9.png";

import minusPath0 from "./assets/glyphdata/minus/0.png";
import minusPath1 from "./assets/glyphdata/minus/1.png";
import minusPath2 from "./assets/glyphdata/minus/2.png";
import minusPath3 from "./assets/glyphdata/minus/3.png";
import minusPath4 from "./assets/glyphdata/minus/4.png";
import minusPath5 from "./assets/glyphdata/minus/5.png";
import minusPath6 from "./assets/glyphdata/minus/6.png";
import minusPath7 from "./assets/glyphdata/minus/7.png";
import minusPath8 from "./assets/glyphdata/minus/8.png";
import minusPath9 from "./assets/glyphdata/minus/9.png";

import parenClosePath0 from "./assets/glyphdata/paren_close/0.png";
import parenClosePath1 from "./assets/glyphdata/paren_close/1.png";
import parenClosePath2 from "./assets/glyphdata/paren_close/2.png";
import parenClosePath3 from "./assets/glyphdata/paren_close/3.png";
import parenClosePath4 from "./assets/glyphdata/paren_close/4.png";
import parenClosePath5 from "./assets/glyphdata/paren_close/5.png";
import parenClosePath6 from "./assets/glyphdata/paren_close/6.png";
import parenClosePath7 from "./assets/glyphdata/paren_close/7.png";
import parenClosePath8 from "./assets/glyphdata/paren_close/8.png";
import parenClosePath9 from "./assets/glyphdata/paren_close/9.png";

import parenOpenPath0 from "./assets/glyphdata/paren_open/0.png";
import parenOpenPath1 from "./assets/glyphdata/paren_open/1.png";
import parenOpenPath2 from "./assets/glyphdata/paren_open/2.png";
import parenOpenPath3 from "./assets/glyphdata/paren_open/3.png";
import parenOpenPath4 from "./assets/glyphdata/paren_open/4.png";
import parenOpenPath5 from "./assets/glyphdata/paren_open/5.png";
import parenOpenPath6 from "./assets/glyphdata/paren_open/6.png";
import parenOpenPath7 from "./assets/glyphdata/paren_open/7.png";
import parenOpenPath8 from "./assets/glyphdata/paren_open/8.png";
import parenOpenPath9 from "./assets/glyphdata/paren_open/9.png";

import percentPath0 from "./assets/glyphdata/percent/0.png";
import percentPath1 from "./assets/glyphdata/percent/1.png";
import percentPath2 from "./assets/glyphdata/percent/2.png";
import percentPath3 from "./assets/glyphdata/percent/3.png";
import percentPath4 from "./assets/glyphdata/percent/4.png";
import percentPath5 from "./assets/glyphdata/percent/5.png";
import percentPath6 from "./assets/glyphdata/percent/6.png";
import percentPath7 from "./assets/glyphdata/percent/7.png";
import percentPath8 from "./assets/glyphdata/percent/8.png";
import percentPath9 from "./assets/glyphdata/percent/9.png";

import periodPath0 from "./assets/glyphdata/period/0.png";
import periodPath1 from "./assets/glyphdata/period/1.png";
import periodPath2 from "./assets/glyphdata/period/2.png";
import periodPath3 from "./assets/glyphdata/period/3.png";
import periodPath4 from "./assets/glyphdata/period/4.png";
import periodPath5 from "./assets/glyphdata/period/5.png";
import periodPath6 from "./assets/glyphdata/period/6.png";
import periodPath7 from "./assets/glyphdata/period/7.png";
import periodPath8 from "./assets/glyphdata/period/8.png";
import periodPath9 from "./assets/glyphdata/period/9.png";

import pipePath0 from "./assets/glyphdata/pipe/0.png";
import pipePath1 from "./assets/glyphdata/pipe/1.png";
import pipePath2 from "./assets/glyphdata/pipe/2.png";
import pipePath3 from "./assets/glyphdata/pipe/3.png";
import pipePath4 from "./assets/glyphdata/pipe/4.png";
import pipePath5 from "./assets/glyphdata/pipe/5.png";
import pipePath6 from "./assets/glyphdata/pipe/6.png";
import pipePath7 from "./assets/glyphdata/pipe/7.png";
import pipePath8 from "./assets/glyphdata/pipe/8.png";
import pipePath9 from "./assets/glyphdata/pipe/9.png";

import plusPath0 from "./assets/glyphdata/plus/0.png";
import plusPath1 from "./assets/glyphdata/plus/1.png";
import plusPath2 from "./assets/glyphdata/plus/2.png";
import plusPath3 from "./assets/glyphdata/plus/3.png";
import plusPath4 from "./assets/glyphdata/plus/4.png";
import plusPath5 from "./assets/glyphdata/plus/5.png";
import plusPath6 from "./assets/glyphdata/plus/6.png";
import plusPath7 from "./assets/glyphdata/plus/7.png";
import plusPath8 from "./assets/glyphdata/plus/8.png";
import plusPath9 from "./assets/glyphdata/plus/9.png";

import poundPath0 from "./assets/glyphdata/pound/0.png";
import poundPath1 from "./assets/glyphdata/pound/1.png";
import poundPath2 from "./assets/glyphdata/pound/2.png";
import poundPath3 from "./assets/glyphdata/pound/3.png";
import poundPath4 from "./assets/glyphdata/pound/4.png";
import poundPath5 from "./assets/glyphdata/pound/5.png";
import poundPath6 from "./assets/glyphdata/pound/6.png";
import poundPath7 from "./assets/glyphdata/pound/7.png";
import poundPath8 from "./assets/glyphdata/pound/8.png";
import poundPath9 from "./assets/glyphdata/pound/9.png";

import questionPath0 from "./assets/glyphdata/question/0.png";
import questionPath1 from "./assets/glyphdata/question/1.png";
import questionPath2 from "./assets/glyphdata/question/2.png";
import questionPath3 from "./assets/glyphdata/question/3.png";
import questionPath4 from "./assets/glyphdata/question/4.png";
import questionPath5 from "./assets/glyphdata/question/5.png";
import questionPath6 from "./assets/glyphdata/question/6.png";
import questionPath7 from "./assets/glyphdata/question/7.png";
import questionPath8 from "./assets/glyphdata/question/8.png";
import questionPath9 from "./assets/glyphdata/question/9.png";

import semiquoPath0 from "./assets/glyphdata/semiquo/0.png";
import semiquoPath1 from "./assets/glyphdata/semiquo/1.png";
import semiquoPath2 from "./assets/glyphdata/semiquo/2.png";
import semiquoPath3 from "./assets/glyphdata/semiquo/3.png";
import semiquoPath4 from "./assets/glyphdata/semiquo/4.png";
import semiquoPath5 from "./assets/glyphdata/semiquo/5.png";
import semiquoPath6 from "./assets/glyphdata/semiquo/6.png";
import semiquoPath7 from "./assets/glyphdata/semiquo/7.png";
import semiquoPath8 from "./assets/glyphdata/semiquo/8.png";
import semiquoPath9 from "./assets/glyphdata/semiquo/9.png";

import sqrOpenPath0 from "./assets/glyphdata/sqr_open/0.png";
import sqrOpenPath1 from "./assets/glyphdata/sqr_open/1.png";
import sqrOpenPath2 from "./assets/glyphdata/sqr_open/2.png";
import sqrOpenPath3 from "./assets/glyphdata/sqr_open/3.png";
import sqrOpenPath4 from "./assets/glyphdata/sqr_open/4.png";
import sqrOpenPath5 from "./assets/glyphdata/sqr_open/5.png";
import sqrOpenPath6 from "./assets/glyphdata/sqr_open/6.png";
import sqrOpenPath7 from "./assets/glyphdata/sqr_open/7.png";
import sqrOpenPath8 from "./assets/glyphdata/sqr_open/8.png";
import sqrOpenPath9 from "./assets/glyphdata/sqr_open/9.png";

import sqrClosePath0 from "./assets/glyphdata/sqr_close/0.png";
import sqrClosePath1 from "./assets/glyphdata/sqr_close/1.png";
import sqrClosePath2 from "./assets/glyphdata/sqr_close/2.png";
import sqrClosePath3 from "./assets/glyphdata/sqr_close/3.png";
import sqrClosePath4 from "./assets/glyphdata/sqr_close/4.png";
import sqrClosePath5 from "./assets/glyphdata/sqr_close/5.png";
import sqrClosePath6 from "./assets/glyphdata/sqr_close/6.png";
import sqrClosePath7 from "./assets/glyphdata/sqr_close/7.png";
import sqrClosePath8 from "./assets/glyphdata/sqr_close/8.png";
import sqrClosePath9 from "./assets/glyphdata/sqr_close/9.png";

import squoPath0 from "./assets/glyphdata/squo/0.png";
import squoPath1 from "./assets/glyphdata/squo/1.png";
import squoPath2 from "./assets/glyphdata/squo/2.png";
import squoPath3 from "./assets/glyphdata/squo/3.png";
import squoPath4 from "./assets/glyphdata/squo/4.png";
import squoPath5 from "./assets/glyphdata/squo/5.png";
import squoPath6 from "./assets/glyphdata/squo/6.png";
import squoPath7 from "./assets/glyphdata/squo/7.png";
import squoPath8 from "./assets/glyphdata/squo/8.png";
import squoPath9 from "./assets/glyphdata/squo/9.png";

import tildePath0 from "./assets/glyphdata/tilde/0.png";
import tildePath1 from "./assets/glyphdata/tilde/1.png";
import tildePath2 from "./assets/glyphdata/tilde/2.png";
import tildePath3 from "./assets/glyphdata/tilde/3.png";
import tildePath4 from "./assets/glyphdata/tilde/4.png";
import tildePath5 from "./assets/glyphdata/tilde/5.png";
import tildePath6 from "./assets/glyphdata/tilde/6.png";
import tildePath7 from "./assets/glyphdata/tilde/7.png";
import tildePath8 from "./assets/glyphdata/tilde/8.png";
import tildePath9 from "./assets/glyphdata/tilde/9.png";

import underscorePath0 from "./assets/glyphdata/underscore/0.png";
import underscorePath1 from "./assets/glyphdata/underscore/1.png";
import underscorePath2 from "./assets/glyphdata/underscore/2.png";
import underscorePath3 from "./assets/glyphdata/underscore/3.png";
import underscorePath4 from "./assets/glyphdata/underscore/4.png";
import underscorePath5 from "./assets/glyphdata/underscore/5.png";
import underscorePath6 from "./assets/glyphdata/underscore/6.png";
import underscorePath7 from "./assets/glyphdata/underscore/7.png";
import underscorePath8 from "./assets/glyphdata/underscore/8.png";
import underscorePath9 from "./assets/glyphdata/underscore/9.png";

export const GLYPH_IMAGE_SIZE = 32;
export const GLYPH_SPRITES_TOTAL = 10;
export const GLYPHS_PER_SPRITE_TOTAL = 1000;
export const GLYPHS_PER_SPRITE_COLS = 32;
export const GLYPHS_TOTAL = 9990;

export type GlyphGridArea = "uppercase" | "lowercase" | "special" | "pangram";

export interface GlyphGroup {
  name: string;
  gridArea: GlyphGridArea;
  glyphs: Glyph[];
}
export interface Glyph {
  char: string;
  spriteUrls: string[];
}

export const CHARACTER_SET: GlyphGroup[] = [
  {
    name: "Uppercase",
    gridArea: "uppercase",
    glyphs: [
      {
        char: "A",
        spriteUrls: [
          APath0,
          APath1,
          APath1,
          APath2,
          APath3,
          APath4,
          APath5,
          APath6,
          APath7,
          APath8,
          APath9
        ]
      },
      {
        char: "B",
        spriteUrls: [
          BPath0,
          BPath1,
          BPath1,
          BPath2,
          BPath3,
          BPath4,
          BPath5,
          BPath6,
          BPath7,
          BPath8,
          BPath9
        ]
      },
      {
        char: "C",
        spriteUrls: [
          CPath0,
          CPath1,
          CPath1,
          CPath2,
          CPath3,
          CPath4,
          CPath5,
          CPath6,
          CPath7,
          CPath8,
          CPath9
        ]
      },
      {
        char: "D",
        spriteUrls: [
          DPath0,
          DPath1,
          DPath1,
          DPath2,
          DPath3,
          DPath4,
          DPath5,
          DPath6,
          DPath7,
          DPath8,
          DPath9
        ]
      },
      {
        char: "E",
        spriteUrls: [
          EPath0,
          EPath1,
          EPath1,
          EPath2,
          EPath3,
          EPath4,
          EPath5,
          EPath6,
          EPath7,
          EPath8,
          EPath9
        ]
      },
      {
        char: "F",
        spriteUrls: [
          FPath0,
          FPath1,
          FPath1,
          FPath2,
          FPath3,
          FPath4,
          FPath5,
          FPath6,
          FPath7,
          FPath8,
          FPath9
        ]
      },
      {
        char: "G",
        spriteUrls: [
          GPath0,
          GPath1,
          GPath1,
          GPath2,
          GPath3,
          GPath4,
          GPath5,
          GPath6,
          GPath7,
          GPath8,
          GPath9
        ]
      },
      {
        char: "H",
        spriteUrls: [
          HPath0,
          HPath1,
          HPath1,
          HPath2,
          HPath3,
          HPath4,
          HPath5,
          HPath6,
          HPath7,
          HPath8,
          HPath9
        ]
      },
      {
        char: "I",
        spriteUrls: [
          IPath0,
          IPath1,
          IPath1,
          IPath2,
          IPath3,
          IPath4,
          IPath5,
          IPath6,
          IPath7,
          IPath8,
          IPath9
        ]
      },
      {
        char: "J",
        spriteUrls: [
          JPath0,
          JPath1,
          JPath1,
          JPath2,
          JPath3,
          JPath4,
          JPath5,
          JPath6,
          JPath7,
          JPath8,
          JPath9
        ]
      },
      {
        char: "K",
        spriteUrls: [
          KPath0,
          KPath1,
          KPath1,
          KPath2,
          KPath3,
          KPath4,
          KPath5,
          KPath6,
          KPath7,
          KPath8,
          KPath9
        ]
      },
      {
        char: "L",
        spriteUrls: [
          LPath0,
          LPath1,
          LPath1,
          LPath2,
          LPath3,
          LPath4,
          LPath5,
          LPath6,
          LPath7,
          LPath8,
          LPath9
        ]
      },
      {
        char: "M",
        spriteUrls: [
          MPath0,
          MPath1,
          MPath1,
          MPath2,
          MPath3,
          MPath4,
          MPath5,
          MPath6,
          MPath7,
          MPath8,
          MPath9
        ]
      },
      {
        char: "N",
        spriteUrls: [
          NPath0,
          NPath1,
          NPath1,
          NPath2,
          NPath3,
          NPath4,
          NPath5,
          NPath6,
          NPath7,
          NPath8,
          NPath9
        ]
      },
      {
        char: "O",
        spriteUrls: [
          OPath0,
          OPath1,
          OPath1,
          OPath2,
          OPath3,
          OPath4,
          OPath5,
          OPath6,
          OPath7,
          OPath8,
          OPath9
        ]
      },
      {
        char: "P",
        spriteUrls: [
          PPath0,
          PPath1,
          PPath1,
          PPath2,
          PPath3,
          PPath4,
          PPath5,
          PPath6,
          PPath7,
          PPath8,
          PPath9
        ]
      },
      {
        char: "Q",
        spriteUrls: [
          QPath0,
          QPath1,
          QPath1,
          QPath2,
          QPath3,
          QPath4,
          QPath5,
          QPath6,
          QPath7,
          QPath8,
          QPath9
        ]
      },
      {
        char: "R",
        spriteUrls: [
          RPath0,
          RPath1,
          RPath1,
          RPath2,
          RPath3,
          RPath4,
          RPath5,
          RPath6,
          RPath7,
          RPath8,
          RPath9
        ]
      },
      {
        char: "S",
        spriteUrls: [
          SPath0,
          SPath1,
          SPath1,
          SPath2,
          SPath3,
          SPath4,
          SPath5,
          SPath6,
          SPath7,
          SPath8,
          SPath9
        ]
      },
      {
        char: "T",
        spriteUrls: [
          TPath0,
          TPath1,
          TPath1,
          TPath2,
          TPath3,
          TPath4,
          TPath5,
          TPath6,
          TPath7,
          TPath8,
          TPath9
        ]
      },
      {
        char: "U",
        spriteUrls: [
          UPath0,
          UPath1,
          UPath1,
          UPath2,
          UPath3,
          UPath4,
          UPath5,
          UPath6,
          UPath7,
          UPath8,
          UPath9
        ]
      },
      {
        char: "V",
        spriteUrls: [
          VPath0,
          VPath1,
          VPath1,
          VPath2,
          VPath3,
          VPath4,
          VPath5,
          VPath6,
          VPath7,
          VPath8,
          VPath9
        ]
      },
      {
        char: "W",
        spriteUrls: [
          WPath0,
          WPath1,
          WPath1,
          WPath2,
          WPath3,
          WPath4,
          WPath5,
          WPath6,
          WPath7,
          WPath8,
          WPath9
        ]
      },
      {
        char: "X",
        spriteUrls: [
          XPath0,
          XPath1,
          XPath1,
          XPath2,
          XPath3,
          XPath4,
          XPath5,
          XPath6,
          XPath7,
          XPath8,
          XPath9
        ]
      },
      {
        char: "Y",
        spriteUrls: [
          YPath0,
          YPath1,
          YPath1,
          YPath2,
          YPath3,
          YPath4,
          YPath5,
          YPath6,
          YPath7,
          YPath8,
          YPath9
        ]
      },
      {
        char: "Z",
        spriteUrls: [
          ZPath0,
          ZPath1,
          ZPath1,
          ZPath2,
          ZPath3,
          ZPath4,
          ZPath5,
          ZPath6,
          ZPath7,
          ZPath8,
          ZPath9
        ]
      }
    ]
  },
  {
    name: "Lowercase",
    gridArea: "lowercase",
    glyphs: [
      {
        char: "a",
        spriteUrls: [
          aPath0,
          aPath1,
          aPath1,
          aPath2,
          aPath3,
          aPath4,
          aPath5,
          aPath6,
          aPath7,
          aPath8,
          aPath9
        ]
      },
      {
        char: "b",
        spriteUrls: [
          bPath0,
          bPath1,
          bPath1,
          bPath2,
          bPath3,
          bPath4,
          bPath5,
          bPath6,
          bPath7,
          bPath8,
          bPath9
        ]
      },
      {
        char: "c",
        spriteUrls: [
          cPath0,
          cPath1,
          cPath1,
          cPath2,
          cPath3,
          cPath4,
          cPath5,
          cPath6,
          cPath7,
          cPath8,
          cPath9
        ]
      },
      {
        char: "d",
        spriteUrls: [
          dPath0,
          dPath1,
          dPath1,
          dPath2,
          dPath3,
          dPath4,
          dPath5,
          dPath6,
          dPath7,
          dPath8,
          dPath9
        ]
      },
      {
        char: "e",
        spriteUrls: [
          ePath0,
          ePath1,
          ePath1,
          ePath2,
          ePath3,
          ePath4,
          ePath5,
          ePath6,
          ePath7,
          ePath8,
          ePath9
        ]
      },
      {
        char: "f",
        spriteUrls: [
          fPath0,
          fPath1,
          fPath1,
          fPath2,
          fPath3,
          fPath4,
          fPath5,
          fPath6,
          fPath7,
          fPath8,
          fPath9
        ]
      },
      {
        char: "g",
        spriteUrls: [
          gPath0,
          gPath1,
          gPath1,
          gPath2,
          gPath3,
          gPath4,
          gPath5,
          gPath6,
          gPath7,
          gPath8,
          gPath9
        ]
      },
      {
        char: "h",
        spriteUrls: [
          hPath0,
          hPath1,
          hPath1,
          hPath2,
          hPath3,
          hPath4,
          hPath5,
          hPath6,
          hPath7,
          hPath8,
          hPath9
        ]
      },
      {
        char: "i",
        spriteUrls: [
          iPath0,
          iPath1,
          iPath1,
          iPath2,
          iPath3,
          iPath4,
          iPath5,
          iPath6,
          iPath7,
          iPath8,
          iPath9
        ]
      },
      {
        char: "j",
        spriteUrls: [
          jPath0,
          jPath1,
          jPath1,
          jPath2,
          jPath3,
          jPath4,
          jPath5,
          jPath6,
          jPath7,
          jPath8,
          jPath9
        ]
      },
      {
        char: "k",
        spriteUrls: [
          kPath0,
          kPath1,
          kPath1,
          kPath2,
          kPath3,
          kPath4,
          kPath5,
          kPath6,
          kPath7,
          kPath8,
          kPath9
        ]
      },
      {
        char: "l",
        spriteUrls: [
          lPath0,
          lPath1,
          lPath1,
          lPath2,
          lPath3,
          lPath4,
          lPath5,
          lPath6,
          lPath7,
          lPath8,
          lPath9
        ]
      },
      {
        char: "m",
        spriteUrls: [
          mPath0,
          mPath1,
          mPath1,
          mPath2,
          mPath3,
          mPath4,
          mPath5,
          mPath6,
          mPath7,
          mPath8,
          mPath9
        ]
      },
      {
        char: "n",
        spriteUrls: [
          nPath0,
          nPath1,
          nPath1,
          nPath2,
          nPath3,
          nPath4,
          nPath5,
          nPath6,
          nPath7,
          nPath8,
          nPath9
        ]
      },
      {
        char: "o",
        spriteUrls: [
          oPath0,
          oPath1,
          oPath1,
          oPath2,
          oPath3,
          oPath4,
          oPath5,
          oPath6,
          oPath7,
          oPath8,
          oPath9
        ]
      },
      {
        char: "p",
        spriteUrls: [
          pPath0,
          pPath1,
          pPath1,
          pPath2,
          pPath3,
          pPath4,
          pPath5,
          pPath6,
          pPath7,
          pPath8,
          pPath9
        ]
      },
      {
        char: "q",
        spriteUrls: [
          qPath0,
          qPath1,
          qPath1,
          qPath2,
          qPath3,
          qPath4,
          qPath5,
          qPath6,
          qPath7,
          qPath8,
          qPath9
        ]
      },
      {
        char: "r",
        spriteUrls: [
          rPath0,
          rPath1,
          rPath1,
          rPath2,
          rPath3,
          rPath4,
          rPath5,
          rPath6,
          rPath7,
          rPath8,
          rPath9
        ]
      },
      {
        char: "s",
        spriteUrls: [
          sPath0,
          sPath1,
          sPath1,
          sPath2,
          sPath3,
          sPath4,
          sPath5,
          sPath6,
          sPath7,
          sPath8,
          sPath9
        ]
      },
      {
        char: "t",
        spriteUrls: [
          tPath0,
          tPath1,
          tPath1,
          tPath2,
          tPath3,
          tPath4,
          tPath5,
          tPath6,
          tPath7,
          tPath8,
          tPath9
        ]
      },
      {
        char: "u",
        spriteUrls: [
          uPath0,
          uPath1,
          uPath1,
          uPath2,
          uPath3,
          uPath4,
          uPath5,
          uPath6,
          uPath7,
          uPath8,
          uPath9
        ]
      },
      {
        char: "v",
        spriteUrls: [
          vPath0,
          vPath1,
          vPath1,
          vPath2,
          vPath3,
          vPath4,
          vPath5,
          vPath6,
          vPath7,
          vPath8,
          vPath9
        ]
      },
      {
        char: "w",
        spriteUrls: [
          wPath0,
          wPath1,
          wPath1,
          wPath2,
          wPath3,
          wPath4,
          wPath5,
          wPath6,
          wPath7,
          wPath8,
          wPath9
        ]
      },
      {
        char: "x",
        spriteUrls: [
          xPath0,
          xPath1,
          xPath1,
          xPath2,
          xPath3,
          xPath4,
          xPath5,
          xPath6,
          xPath7,
          xPath8,
          xPath9
        ]
      },
      {
        char: "y",
        spriteUrls: [
          yPath0,
          yPath1,
          yPath1,
          yPath2,
          yPath3,
          yPath4,
          yPath5,
          yPath6,
          yPath7,
          yPath8,
          yPath9
        ]
      },
      {
        char: "z",
        spriteUrls: [
          zPath0,
          zPath1,
          zPath1,
          zPath2,
          zPath3,
          zPath4,
          zPath5,
          zPath6,
          zPath7,
          zPath8,
          zPath9
        ]
      }
    ]
  },
  {
    name: "Digits",
    gridArea: "special",
    glyphs: [
      {
        char: "0",
        spriteUrls: [
          zeroPath0,
          zeroPath1,
          zeroPath1,
          zeroPath2,
          zeroPath3,
          zeroPath4,
          zeroPath5,
          zeroPath6,
          zeroPath7,
          zeroPath8,
          zeroPath9
        ]
      },
      {
        char: "1",
        spriteUrls: [
          onePath0,
          onePath1,
          onePath1,
          onePath2,
          onePath3,
          onePath4,
          onePath5,
          onePath6,
          onePath7,
          onePath8,
          onePath9
        ]
      },
      {
        char: "2",
        spriteUrls: [
          twoPath0,
          twoPath1,
          twoPath1,
          twoPath2,
          twoPath3,
          twoPath4,
          twoPath5,
          twoPath6,
          twoPath7,
          twoPath8,
          twoPath9
        ]
      },
      {
        char: "3",
        spriteUrls: [
          threePath0,
          threePath1,
          threePath1,
          threePath2,
          threePath3,
          threePath4,
          threePath5,
          threePath6,
          threePath7,
          threePath8,
          threePath9
        ]
      },
      {
        char: "4",
        spriteUrls: [
          fourPath0,
          fourPath1,
          fourPath1,
          fourPath2,
          fourPath3,
          fourPath4,
          fourPath5,
          fourPath6,
          fourPath7,
          fourPath8,
          fourPath9
        ]
      },
      {
        char: "5",
        spriteUrls: [
          fivePath0,
          fivePath1,
          fivePath1,
          fivePath2,
          fivePath3,
          fivePath4,
          fivePath5,
          fivePath6,
          fivePath7,
          fivePath8,
          fivePath9
        ]
      },
      {
        char: "6",
        spriteUrls: [
          sixPath0,
          sixPath1,
          sixPath1,
          sixPath2,
          sixPath3,
          sixPath4,
          sixPath5,
          sixPath6,
          sixPath7,
          sixPath8,
          sixPath9
        ]
      },
      {
        char: "7",
        spriteUrls: [
          sevenPath0,
          sevenPath1,
          sevenPath1,
          sevenPath2,
          sevenPath3,
          sevenPath4,
          sevenPath5,
          sevenPath6,
          sevenPath7,
          sevenPath8,
          sevenPath9
        ]
      },
      {
        char: "8",
        spriteUrls: [
          eightPath0,
          eightPath1,
          eightPath1,
          eightPath2,
          eightPath3,
          eightPath4,
          eightPath5,
          eightPath6,
          eightPath7,
          eightPath8,
          eightPath9
        ]
      },
      {
        char: "9",
        spriteUrls: [
          ninePath0,
          ninePath1,
          ninePath1,
          ninePath2,
          ninePath3,
          ninePath4,
          ninePath5,
          ninePath6,
          ninePath7,
          ninePath8,
          ninePath9
        ]
      }
    ]
  },
  {
    name: "Special",
    gridArea: "special",
    glyphs: [
      {
        char: "&",
        spriteUrls: [
          ampPath0,
          ampPath1,
          ampPath1,
          ampPath2,
          ampPath3,
          ampPath4,
          ampPath5,
          ampPath6,
          ampPath7,
          ampPath8,
          ampPath9
        ]
      },
      {
        char: "*",
        spriteUrls: [
          asteriskPath0,
          asteriskPath1,
          asteriskPath1,
          asteriskPath2,
          asteriskPath3,
          asteriskPath4,
          asteriskPath5,
          asteriskPath6,
          asteriskPath7,
          asteriskPath8,
          asteriskPath9
        ]
      },
      {
        char: "@",
        spriteUrls: [
          atPath0,
          atPath1,
          atPath1,
          atPath2,
          atPath3,
          atPath4,
          atPath5,
          atPath6,
          atPath7,
          atPath8,
          atPath9
        ]
      },
      {
        char: "\\",
        spriteUrls: [
          backslashPath0,
          backslashPath1,
          backslashPath1,
          backslashPath2,
          backslashPath3,
          backslashPath4,
          backslashPath5,
          backslashPath6,
          backslashPath7,
          backslashPath8,
          backslashPath9
        ]
      },
      {
        char: "`",
        spriteUrls: [
          backtickPath0,
          backtickPath1,
          backtickPath1,
          backtickPath2,
          backtickPath3,
          backtickPath4,
          backtickPath5,
          backtickPath6,
          backtickPath7,
          backtickPath8,
          backtickPath9
        ]
      },
      {
        char: "^",
        spriteUrls: [
          caretPath0,
          caretPath1,
          caretPath1,
          caretPath2,
          caretPath3,
          caretPath4,
          caretPath5,
          caretPath6,
          caretPath7,
          caretPath8,
          caretPath9
        ]
      },
      {
        char: "¢",
        spriteUrls: [
          centPath0,
          centPath1,
          centPath1,
          centPath2,
          centPath3,
          centPath4,
          centPath5,
          centPath6,
          centPath7,
          centPath8,
          centPath9
        ]
      },
      {
        char: ":",
        spriteUrls: [
          colonPath0,
          colonPath1,
          colonPath1,
          colonPath2,
          colonPath3,
          colonPath4,
          colonPath5,
          colonPath6,
          colonPath7,
          colonPath8,
          colonPath9
        ]
      },
      {
        char: ",",
        spriteUrls: [
          commaPath0,
          commaPath1,
          commaPath1,
          commaPath2,
          commaPath3,
          commaPath4,
          commaPath5,
          commaPath6,
          commaPath7,
          commaPath8,
          commaPath9
        ]
      },
      {
        char: "©",
        spriteUrls: [
          copyPath0,
          copyPath1,
          copyPath1,
          copyPath2,
          copyPath3,
          copyPath4,
          copyPath5,
          copyPath6,
          copyPath7,
          copyPath8,
          copyPath9
        ]
      },
      {
        char: "{",
        spriteUrls: [
          curlyOpenPath0,
          curlyOpenPath1,
          curlyOpenPath1,
          curlyOpenPath2,
          curlyOpenPath3,
          curlyOpenPath4,
          curlyOpenPath5,
          curlyOpenPath6,
          curlyOpenPath7,
          curlyOpenPath8,
          curlyOpenPath9
        ]
      },
      {
        char: "}",
        spriteUrls: [
          curlyClosePath0,
          curlyClosePath1,
          curlyClosePath1,
          curlyClosePath2,
          curlyClosePath3,
          curlyClosePath4,
          curlyClosePath5,
          curlyClosePath6,
          curlyClosePath7,
          curlyClosePath8,
          curlyClosePath9
        ]
      },
      {
        char: "$",
        spriteUrls: [
          dollarPath0,
          dollarPath1,
          dollarPath1,
          dollarPath2,
          dollarPath3,
          dollarPath4,
          dollarPath5,
          dollarPath6,
          dollarPath7,
          dollarPath8,
          dollarPath9
        ]
      },
      {
        char: '"',
        spriteUrls: [
          dquoPath0,
          dquoPath1,
          dquoPath1,
          dquoPath2,
          dquoPath3,
          dquoPath4,
          dquoPath5,
          dquoPath6,
          dquoPath7,
          dquoPath8,
          dquoPath9
        ]
      },
      {
        char: "<",
        spriteUrls: [
          ltPath0,
          ltPath1,
          ltPath1,
          ltPath2,
          ltPath3,
          ltPath4,
          ltPath5,
          ltPath6,
          ltPath7,
          ltPath8,
          ltPath9
        ]
      },
      {
        char: "=",
        spriteUrls: [
          eqPath0,
          eqPath1,
          eqPath1,
          eqPath2,
          eqPath3,
          eqPath4,
          eqPath5,
          eqPath6,
          eqPath7,
          eqPath8,
          eqPath9
        ]
      },
      {
        char: ">",
        spriteUrls: [
          gtPath0,
          gtPath1,
          gtPath1,
          gtPath2,
          gtPath3,
          gtPath4,
          gtPath5,
          gtPath6,
          gtPath7,
          gtPath8,
          gtPath9
        ]
      },
      {
        char: "€",
        spriteUrls: [
          euroPath0,
          euroPath1,
          euroPath1,
          euroPath2,
          euroPath3,
          euroPath4,
          euroPath5,
          euroPath6,
          euroPath7,
          euroPath8,
          euroPath9
        ]
      },
      {
        char: "!",
        spriteUrls: [
          exclamationPath0,
          exclamationPath1,
          exclamationPath1,
          exclamationPath2,
          exclamationPath3,
          exclamationPath4,
          exclamationPath5,
          exclamationPath6,
          exclamationPath7,
          exclamationPath8,
          exclamationPath9
        ]
      },
      {
        char: "/",
        spriteUrls: [
          fwSlashPath0,
          fwSlashPath1,
          fwSlashPath1,
          fwSlashPath2,
          fwSlashPath3,
          fwSlashPath4,
          fwSlashPath5,
          fwSlashPath6,
          fwSlashPath7,
          fwSlashPath8,
          fwSlashPath9
        ]
      },
      {
        char: "#",
        spriteUrls: [
          hashPath0,
          hashPath1,
          hashPath1,
          hashPath2,
          hashPath3,
          hashPath4,
          hashPath5,
          hashPath6,
          hashPath7,
          hashPath8,
          hashPath9
        ]
      },
      {
        char: "-",
        spriteUrls: [
          minusPath0,
          minusPath1,
          minusPath1,
          minusPath2,
          minusPath3,
          minusPath4,
          minusPath5,
          minusPath6,
          minusPath7,
          minusPath8,
          minusPath9
        ]
      },
      {
        char: "(",
        spriteUrls: [
          parenOpenPath0,
          parenOpenPath1,
          parenOpenPath1,
          parenOpenPath2,
          parenOpenPath3,
          parenOpenPath4,
          parenOpenPath5,
          parenOpenPath6,
          parenOpenPath7,
          parenOpenPath8,
          parenOpenPath9
        ]
      },
      {
        char: ")",
        spriteUrls: [
          parenClosePath0,
          parenClosePath1,
          parenClosePath1,
          parenClosePath2,
          parenClosePath3,
          parenClosePath4,
          parenClosePath5,
          parenClosePath6,
          parenClosePath7,
          parenClosePath8,
          parenClosePath9
        ]
      },
      {
        char: "%",
        spriteUrls: [
          percentPath0,
          percentPath1,
          percentPath1,
          percentPath2,
          percentPath3,
          percentPath4,
          percentPath5,
          percentPath6,
          percentPath7,
          percentPath8,
          percentPath9
        ]
      },
      {
        char: ".",
        spriteUrls: [
          periodPath0,
          periodPath1,
          periodPath1,
          periodPath2,
          periodPath3,
          periodPath4,
          periodPath5,
          periodPath6,
          periodPath7,
          periodPath8,
          periodPath9
        ]
      },
      {
        char: "|",
        spriteUrls: [
          pipePath0,
          pipePath1,
          pipePath1,
          pipePath2,
          pipePath3,
          pipePath4,
          pipePath5,
          pipePath6,
          pipePath7,
          pipePath8,
          pipePath9
        ]
      },
      {
        char: "+",
        spriteUrls: [
          plusPath0,
          plusPath1,
          plusPath1,
          plusPath2,
          plusPath3,
          plusPath4,
          plusPath5,
          plusPath6,
          plusPath7,
          plusPath8,
          plusPath9
        ]
      },
      {
        char: "£",
        spriteUrls: [
          poundPath0,
          poundPath1,
          poundPath1,
          poundPath2,
          poundPath3,
          poundPath4,
          poundPath5,
          poundPath6,
          poundPath7,
          poundPath8,
          poundPath9
        ]
      },
      {
        char: "?",
        spriteUrls: [
          questionPath0,
          questionPath1,
          questionPath1,
          questionPath2,
          questionPath3,
          questionPath4,
          questionPath5,
          questionPath6,
          questionPath7,
          questionPath8,
          questionPath9
        ]
      },
      {
        char: ";",
        spriteUrls: [
          semiquoPath0,
          semiquoPath1,
          semiquoPath1,
          semiquoPath2,
          semiquoPath3,
          semiquoPath4,
          semiquoPath5,
          semiquoPath6,
          semiquoPath7,
          semiquoPath8,
          semiquoPath9
        ]
      },
      {
        char: "[",
        spriteUrls: [
          sqrOpenPath0,
          sqrOpenPath1,
          sqrOpenPath1,
          sqrOpenPath2,
          sqrOpenPath3,
          sqrOpenPath4,
          sqrOpenPath5,
          sqrOpenPath6,
          sqrOpenPath7,
          sqrOpenPath8,
          sqrOpenPath9
        ]
      },
      {
        char: "]",
        spriteUrls: [
          sqrClosePath0,
          sqrClosePath1,
          sqrClosePath1,
          sqrClosePath2,
          sqrClosePath3,
          sqrClosePath4,
          sqrClosePath5,
          sqrClosePath6,
          sqrClosePath7,
          sqrClosePath8,
          sqrClosePath9
        ]
      },
      {
        char: "'",
        spriteUrls: [
          squoPath0,
          squoPath1,
          squoPath1,
          squoPath2,
          squoPath3,
          squoPath4,
          squoPath5,
          squoPath6,
          squoPath7,
          squoPath8,
          squoPath9
        ]
      },
      {
        char: "~",
        spriteUrls: [
          tildePath0,
          tildePath1,
          tildePath1,
          tildePath2,
          tildePath3,
          tildePath4,
          tildePath5,
          tildePath6,
          tildePath7,
          tildePath8,
          tildePath9
        ]
      },
      {
        char: "_",
        spriteUrls: [
          underscorePath0,
          underscorePath1,
          underscorePath1,
          underscorePath2,
          underscorePath3,
          underscorePath4,
          underscorePath5,
          underscorePath6,
          underscorePath7,
          underscorePath8,
          underscorePath9
        ]
      }
    ]
  }
];

export const ALL_CHARS = _.flatMap(CHARACTER_SET, group =>
  group.glyphs.map(g => g.char)
);

export type GlyphGroupStates = { [groupName: string]: GlyphState[] };

export interface GlyphState {
  glyphIndex: number;
  windSpeedCoefficient: number;
  frozen: boolean;
}

export function getGlyphBackgroundIndex(glyphIndex: number) {
  return Math.floor(glyphIndex / GLYPHS_PER_SPRITE_TOTAL);
}

export function getGlyphBackgroundCoordinates(glyphIndex: number) {
  let bgIndex = getGlyphBackgroundIndex(glyphIndex);
  let indexWithinBg = glyphIndex - bgIndex * GLYPHS_PER_SPRITE_TOTAL;
  let row = Math.floor(indexWithinBg / GLYPHS_PER_SPRITE_COLS);
  let col = Math.floor(indexWithinBg - row * GLYPHS_PER_SPRITE_COLS);
  return { col: col * GLYPH_IMAGE_SIZE, row: row * GLYPH_IMAGE_SIZE };
}

export function getGlyphBackgroundPosition(glyphIndex: number) {
  let { row, col } = getGlyphBackgroundCoordinates(glyphIndex);
  return `-${col}px -${row}px`;
}
