import React from "react";
import classNames from "classnames";

import "./InfoModal.scss";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const InfoModal: React.FC<InfoModalProps> = React.memo(
  ({ isOpen, onClose }) => {
    return (
      <div className={classNames("infoModal", { isOpen })}>
        <div className="infoModal--inner">
          <p>
            Bit Tripper by <a href="https://ctpt.co/">Counterpoint</a> and{" "}
            <a href="https://teamyacht.com/">YACHT</a> is an interactive tool
            for exploring generative typography powered by deep neural networks.
          </p>
          <p>
            This tool allows you to explore a vast space of letterforms
            generated using GAN models trained by Counterpoint using a dataset
            of bitmap fonts, and to generate a complete OpenType font that can
            be used like any regular font file. Hybridizing the functional
            minimalism of bitmap fonts with the effectively infinite
            possibilities of latent space is our way of pulling past and future
            together into a deeply weird present moment.
          </p>
          <p>
            Bit Tripper was made to accompany the release of YACHT’s upcoming
            album, <i>Chain Tripping</i>, which was composed with the help of
            machine learning systems and collaborators around the world.
          </p>
          <button className="infoModal--closeButton" onClick={onClose}>
            x
          </button>
        </div>
      </div>
    );
  }
);
