import React, { useState, useEffect, useCallback } from "react";
import * as _ from "lodash";
import classNames from "classnames";

import { Tape } from "./Tape";

import { CHARACTER_SET, GlyphGroupStates, GLYPHS_TOTAL } from "./glyphs";
import { generateAndDownloadFont } from "./fontGenerator";

import "./App.scss";
import { GridAreas } from "./GridAreas";
import { InfoModal } from "./InfoModal";
import {
  faviconGroup,
  faviconGlyphIndex,
  throttleGenerateFavicon
} from "./favicon";

const ANIMATION_VELOCITY = 0.2;

const App: React.SFC = () => {
  let [glyphStates, setGlyphStates] = useState(_.fromPairs(
    CHARACTER_SET.map(charGroup => [
      charGroup.name,
      charGroup.glyphs.map(() => ({
        glyphIndex: 0,
        windSpeedCoefficient: _.random(0.8, 1.2),
        frozen: false
      }))
    ])
  ) as GlyphGroupStates);
  let [totalDelta, setTotalDelta] = useState(0);
  let [animating, setAnimating] = useState(true);
  let [state, updateState] = useState(0);
  let [infoModalOpen, setInfoModalOpen] = useState(false);

  let onOpenInfoModal = useCallback(() => setInfoModalOpen(true), []);
  let onCloseInfoModal = useCallback(() => setInfoModalOpen(false), []);

  useEffect(() => {
    let running = animating;
    let nextFrame = () => {
      if (!running) return;
      setGlyphStates(glyphStates =>
        calculateNewGlyphIndexes(glyphStates, ANIMATION_VELOCITY)
      );
      setTotalDelta(t => t + ANIMATION_VELOCITY);
      updateState(i => i + 1);
      requestAnimationFrame(nextFrame);
    };
    nextFrame();
    return () => {
      running = false;
    };
  }, [animating]);

  useEffect(() => {
    let glyphIndex =
      glyphStates[faviconGroup.name][faviconGlyphIndex].glyphIndex;
    throttleGenerateFavicon(glyphIndex);
  }, [state, glyphStates]);

  function onTapeWind(delta: number) {
    setGlyphStates(calculateNewGlyphIndexes(glyphStates, delta));
    setTotalDelta(t => t + delta);
    updateState(i => i + 1);
    setAnimating(false);
  }

  function onTapePlay() {
    setAnimating(true);
  }

  function onTapePause() {
    setAnimating(false);
  }

  function onToggleFrozen(group: string, glyphIndex: number) {
    glyphStates[group][glyphIndex].frozen = !glyphStates[group][glyphIndex]
      .frozen;
    updateState(i => i + 1);
  }

  function onGenerateFont() {
    generateAndDownloadFont(glyphStates);
  }

  return (
    <div className={classNames("app", { isPlaying: animating })}>
      <header className="header" role="masthead" />
      <main className="main" role="main">
        <div className="app--controls">
          <div className="app--controlsLeft">
            <button className="playButton" onClick={onTapePlay}>
              Play
            </button>
            <button className="pauseButton" onClick={onTapePause}>
              Pause
            </button>
          </div>
          <div className="app--controlsRight">
            <button className="downloadButton" onClick={onGenerateFont}>
              Generate font
            </button>
            <button className="infoModalButton" onClick={onOpenInfoModal}>
              ?
            </button>
          </div>
        </div>
        <div className="app--contentArea">
          <GridAreas
            glyphStates={glyphStates}
            state={state}
            onToggleFrozen={onToggleFrozen}
          />
        </div>
        <div className="app--footer">
          <a className="ctptLogo" href="https://ctpt.co" target="_blank">
            Counterpoint
          </a>
          <a className="yachtLogo" href="https://teamyacht.com" target="_blank">
            Yacht
          </a>
        </div>
        <Tape position={totalDelta} onWind={onTapeWind} />
      </main>
      <footer className="footer" role="contentinfo" />
      <InfoModal isOpen={infoModalOpen} onClose={onCloseInfoModal} />
    </div>
  );
};

function calculateNewGlyphIndexes(
  glyphStates: GlyphGroupStates,
  delta: number
) {
  for (let groupName of Object.keys(glyphStates)) {
    let group = glyphStates[groupName];
    for (let i = 0; i < group.length; i++) {
      if (group[i].frozen) continue;
      let newGlyphIndex =
        group[i].glyphIndex + delta * group[i].windSpeedCoefficient;
      if (newGlyphIndex < 0) {
        newGlyphIndex += GLYPHS_TOTAL;
      } else if (newGlyphIndex >= GLYPHS_TOTAL) {
        newGlyphIndex -= GLYPHS_TOTAL;
      }
      group[i].glyphIndex = newGlyphIndex;
    }
  }
  return glyphStates;
}

export default App;
