import React, { useEffect } from "react";
import { addWheelListener, removeWheelListener } from "wheel";
import classNames from "classnames";

import "./Tape.scss";

const WHEEL_VELOCITY = 0.33;
const TAPE_SPEED = 2;
const TAPE_LENGTH_PX = 3000;
const TAPE_WRAP_SHIFT = 1000;

interface TapeProps {
  position: number;
  onWind: (delta: number) => void;
}
export const Tape: React.SFC<TapeProps> = ({ position, onWind }) => {
  useEffect(() => {
    function onWheel(evt: WheelEvent) {
      onWind(evt.deltaY * WHEEL_VELOCITY);
    }
    addWheelListener(document.body, onWheel);
    return () => removeWheelListener(document.body, onWheel);
  }, []);

  return (
    <div className={classNames("tapeContainer")}>
      <div
        className="tapeContainer--tape"
        style={{
          height: TAPE_LENGTH_PX,
          transform: `translateY(${getRenderedTapePosition(position)}px)`
        }}
      />
      <div className="tapeContainer--marker" />
    </div>
  );
};

function getRenderedTapePosition(position: number) {
  let spedPosition = position * TAPE_SPEED;
  while (spedPosition > 0) {
    spedPosition -= TAPE_WRAP_SHIFT;
  }
  while (spedPosition < -TAPE_LENGTH_PX + TAPE_WRAP_SHIFT) {
    spedPosition += TAPE_WRAP_SHIFT;
  }
  return spedPosition;
}
