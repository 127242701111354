import React, { useState, useCallback } from "react";
import classNames from "classnames";

import { Pangram } from "./Pangram";
import { GlyphGrid } from "./GlyphGrid";
import { GlyphGroupStates, GlyphGridArea, CHARACTER_SET } from "./glyphs";

import "./GridAreas.scss";

interface GridAreasProps {
  glyphStates: GlyphGroupStates;
  state: number;
  onToggleFrozen: (group: string, glyphIdx: number) => void;
}

const AREAS = [
  { key: "pangram", title: "editor" },
  { key: "uppercase", title: "uppercase" },
  { key: "lowercase", title: "lowercase" },
  { key: "special", title: "special" }
];
const AREA_TRANSFORMS = {
  pangram: "translate(-2.5vw, -2.5vh)",
  uppercase: "translate(-107.5vw, -2.5vh)",
  lowercase: "translate(-2.5vw, -110vh)",
  special: "translate(-107.5vw, -107.5vh)"
};

export type ZoomedInGlyph = {
  char: string;
  charEl: HTMLElement;
} | null;

export const GridAreas: React.SFC<GridAreasProps> = ({
  glyphStates,
  state,
  onToggleFrozen
}) => {
  let [currentArea, setCurrentArea] = useState<GlyphGridArea>("pangram");
  let [zoomedInGlyph, setZoomedInGlyph] = useState<ZoomedInGlyph>(null);

  let changeArea = useCallback(
    (area: GlyphGridArea) => {
      if (zoomedInGlyph) {
        setZoomedInGlyph(null);
        setTimeout(() => setCurrentArea(area), 700);
      } else {
        setCurrentArea(area);
      }
    },
    [zoomedInGlyph]
  );

  return (
    <div className="gridAreas">
      <div
        className="gridAreas--areas"
        style={{ transform: AREA_TRANSFORMS[currentArea] }}
      >
        {AREAS.map(({ key }) => (
          <div
            key={key}
            className={classNames("gridAreas--area", {
              isCurrent: key === currentArea
            })}
          >
            {key === "pangram" && (
              <Pangram
                glyphStates={glyphStates}
                state={state}
                onLetsGoClick={() => setCurrentArea("uppercase")}
              />
            )}
            {key !== "pangram" && (
              <GlyphGrid
                isCurrent={key === currentArea}
                glyphGroups={CHARACTER_SET.filter(
                  group => group.gridArea === key
                )}
                glyphStates={glyphStates}
                zoomedInGlyph={zoomedInGlyph}
                onToggleFrozen={onToggleFrozen}
                onSetZoomedInGlyph={setZoomedInGlyph}
              />
            )}
          </div>
        ))}
      </div>
      <div className="gridAreas--changer">
        {AREAS.map(({ key, title }) => (
          <div
            key={key}
            className={classNames("gridAreas--changerButton", `${key}Button`, {
              isCurrent: currentArea === key
            })}
            onClick={() => changeArea(key as GlyphGridArea)}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};
